<template>
  <div class="header">
    <h1 class="header__logo"> <router-link  to="/"><img src="@/assets/images/layout/logo_top.svg" alt="" class="header-image"></router-link> </h1>
    <!-- user logged -->
    <div class="header__user_logged" v-if="state.headerMode == 'userLogged'" v-click-outside="hideDropdown">
      <a
        href="javascript:void(0)"
        class="header__user_logged_nickname"
        @click="showDropdown()"
      >{{state.name}}</a>
      <div class="header__user_logged__dropdown" v-if="state.showDropdown">
        <a
          href="javascript:void(0)"
          class="header__user_logged__dropdown_logout"
          @click="logout"
        >Logout</a>
      </div>
    </div>
    <!-- organization logged -->
    <div
      class="header__user_logged"
      v-else-if="state.headerMode == 'organizationLogged'"
      v-click-outside="hideDropdown"
    >
      <a
        href="javascript:void(0)"
        class="header__user_logged_nickname"
        @click="showDropdown()"
      >{{state.name}}</a>
      <div class="header__user_logged__dropdown" v-if="state.showDropdown">
        <a
          v-if = "state.is_composition == 'true'"
          href="javascript:void(0)"
          class="header__user_logged__dropdown_change"
          @click="goToSelectOrganization"
        >担当企業切替</a>
        <a
          href="javascript:void(0)"
          class="header__user_logged__dropdown_logout"
          @click="organizationLogout"
        >Logout</a>
      </div>
    </div>
    <!-- unlogged -->
    <div
      v-else
      class="header__to_top"
    >
      <router-link href="javascript:void(0)" :to="{ path: '/' }">TOP</router-link>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/header/Header.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/header/Header.scss" scoped>
</style>
