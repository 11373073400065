import LanguageUtil from "@/commons/LanguageUtil";
import DIContainer from "@/core/DIContainer";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import routerPath from "@/commons/RoutePath";
import CommonService from "@/services/CommonService";
import UserInteractor from "@/domain/usecases/UserInteractor";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import MyConfig from "@/_config";
import { useStore } from "vuex";
import { defineComponent, reactive, onMounted, watchEffect } from "vue";
import { useRouter } from "vue-router";
import RoutePath from '@/commons/RoutePath';
export default defineComponent({
    name: "Header",
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            showDropdown: false,
            headerMode: "",
            name: "",
            is_composition: "",
        });
        function showDropdown() {
            state.showDropdown = true;
        }
        function hideDropdown() {
            state.showDropdown = false;
        }
        function organizationLogout() {
            authInteractor.organizationLogout().then(function (result) {
                store.commit("setUserData", {});
                store.commit("setHeaderMode", "default");
                store.commit("setIsloginStatus", false);
                localStorage.removeItem("accountName");
                localStorage.removeItem("isComposition");
                state.showDropdown = false;
                router.push(routerPath.TOP);
            }).catch(function (error) {
                authInteractor.authCheck().then(function (result) {
                    if (!result.data) {
                        store.commit("setUserData", {});
                        store.commit("setHeaderMode", "default");
                        store.commit("setIsloginStatus", false);
                        localStorage.removeItem("accountName");
                        localStorage.removeItem("isComposition");
                        state.showDropdown = false;
                        router.push(routerPath.TOP);
                    }
                });
            });
        }
        function logout() {
            userInteractor.logout().then(function (result) {
                state.showDropdown = false;
                store.commit("setIsloginStatus", false);
                localStorage.removeItem("accountName");
                store.commit("setHeaderMode", "default");
                window.location.href = MyConfig.signateLogoutUrl;
            }).catch(function (error) {
                authInteractor.authCheck().then(function (result) {
                    if (!result.data) {
                        state.showDropdown = false;
                        store.commit("setIsloginStatus", false);
                        localStorage.removeItem("accountName");
                        store.commit("setHeaderMode", "default");
                        router.push(routerPath.TOP);
                    }
                });
            });
        }
        function configHeader() {
            var checkLogin = CommonService.getCookie("is_login");
            state.name = CommonService.getName();
            state.is_composition = localStorage.getItem("isCompositon") || "";
            if (!checkLogin || !state.name) {
                var mode = "default";
                store.commit("setHeaderMode", mode);
                return;
            }
            else {
                var mode = CommonService.configHeader();
                store.commit("setHeaderMode", mode);
            }
        }
        router.afterEach(function (to, from, failure) {
            configHeader();
        });
        var stopWatch = watchEffect(function () {
            if (CommonService.isUser()) {
                if (store.state.userMenuInfo.nickname && store.state.userMenuInfo.nickname != state.name) {
                    state.name = store.state.userMenuInfo.nickname;
                }
            }
        });
        function goToSelectOrganization() {
            router.push(RoutePath.SELECT_ORGANIZATION);
        }
        onMounted(function () {
            if (CommonService.checkLoginLocal()) {
                state.name = CommonService.getName();
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setHeaderMode" || mutation.type == "setIsloginStatus") {
                    var mode = CommonService.configHeader();
                    state.name = CommonService.getName();
                    state.headerMode = mode;
                }
            });
            if (!CommonService.isUser()) {
                stopWatch();
            }
            else {
                var user = CommonService.getCookie("user");
                var user_infor = {};
                user_infor = JSON.parse(decodeURIComponent(user));
                userInteractor
                    .getUserProfile(user_infor.account_id)
                    .then(function (result) {
                    state.name = result.data.nickname;
                    store.commit("setUserMenuInfo", result.data);
                });
            }
        });
        return {
            state: state,
            store: store,
            goToSelectOrganization: goToSelectOrganization,
            showDropdown: showDropdown,
            hideDropdown: hideDropdown,
            organizationLogout: organizationLogout,
            logout: logout
        };
    }
});
