import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import DeliverableReps from "@/domain/repositories/DeliverableReps";
var DeliverableInteractor = /** @class */ (function () {
    function DeliverableInteractor() {
        this.repos = DIContainer.instance.get(DeliverableReps);
    }
    DeliverableInteractor.prototype.getDeliverable = function (project_id, team_id) {
        return this.repos.getDeliverable(project_id, team_id);
    };
    DeliverableInteractor.prototype.createDeliverable = function (deliverable) {
        return this.repos.createDeliverable(deliverable);
    };
    DeliverableInteractor.prototype.updateDeliverable = function (deliverable) {
        return this.repos.updateDeliverable(deliverable);
    };
    DeliverableInteractor.prototype.browseByOrganization = function (id, publish, publish_to_badge, project_name_after_change) {
        return this.repos.browseByOrganization(id, publish, publish_to_badge, project_name_after_change);
    };
    DeliverableInteractor.prototype.deleteDeliverableFile = function (fileId) {
        return this.repos.deleteDeliverableFile(fileId);
    };
    DeliverableInteractor = __decorate([
        injectable()
    ], DeliverableInteractor);
    return DeliverableInteractor;
}());
export default DeliverableInteractor;
