import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var MasterApi = /** @class */ (function () {
    function MasterApi() {
    }
    MasterApi.prototype.getAllPerfecture = function () {
        return http.get("/prefectures");
    };
    MasterApi.prototype.getAllCitiesByPerfecture = function (id) {
        return http.get("/cities/" + id);
    };
    MasterApi.prototype.searchCorporations = function (keyword) {
        return http.get("/corporations/search", { keyword: keyword });
    };
    MasterApi.prototype.getOneCorporations = function (corporateNumber) {
        return http.get("/corporations", { corporateNumber: corporateNumber });
    };
    MasterApi.prototype.getSkills = function (param) {
        return http.get("/skills", param);
    };
    MasterApi.prototype.getIndustries = function () {
        return http.get("/industries");
    };
    MasterApi.prototype.getOccupations = function () {
        return http.get("/occupations");
    };
    MasterApi.prototype.getAvailableDatetimes = function () {
        return http.get("/available_datetimes");
    };
    MasterApi.prototype.getDataTypes = function () {
        return http.get("/data_types");
    };
    MasterApi.prototype.uploadFile = function (type, id, img_base64) {
        return http.post("/files/upload", { type: type, id: id, img_base64: img_base64 });
    };
    MasterApi = __decorate([
        injectable()
    ], MasterApi);
    return MasterApi;
}());
export default MasterApi;
