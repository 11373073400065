import { __awaiter, __generator } from "tslib";
import CommonService from '@/services/CommonService';
import axios from "axios";
var axiosApiInstance = axios.create();
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var fullResponse;
    return __generator(this, function (_a) {
        fullResponse = config.headers.fullResponse;
        config.headers = {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
            lang: "ja"
        };
        config.headers.fullResponse = fullResponse;
        return [2 /*return*/, config];
    });
}); }, function (error) {
    Promise.reject(error);
});
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(function (response) {
    if (response.config.headers.fullResponse) {
        return response;
    }
    return response.data;
}, function (error) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var BAD_REQUEST, UNAUTHORIZED, FORBIDDEN, NOT_FOUND, SERVER_ERROR, response;
        return __generator(this, function (_b) {
            BAD_REQUEST = 400;
            UNAUTHORIZED = 401;
            FORBIDDEN = 403;
            NOT_FOUND = 404;
            SERVER_ERROR = 500;
            response = error.response;
            response.data.code = (_a = response.status) !== null && _a !== void 0 ? _a : 200;
            if (response.status === BAD_REQUEST) {
                return [2 /*return*/, Promise.reject(response.data)];
            }
            if (response.status === FORBIDDEN) {
                return [2 /*return*/, Promise.reject(response.data)];
            }
            if (response.status === UNAUTHORIZED) {
                if (response.request.responseURL.includes("/auth/check")) {
                    return [2 /*return*/, Promise.reject(response.data)];
                }
                CommonService.deleteCookie("is_login");
                CommonService.deleteCookie("user");
                CommonService.deleteCookie("user_roles");
                localStorage.removeItem("accountName");
                localStorage.removeItem("isComposition");
                sessionStorage.setItem("tokenExpired", "true");
                return [2 /*return*/, window.location.href = "/"];
            }
            if (response.status === NOT_FOUND) {
                return [2 /*return*/, Promise.reject(response.data)];
            }
            if (response.status === SERVER_ERROR) {
                window.dispatchEvent(new CustomEvent('alert-error'));
                return [2 /*return*/, Promise.reject(response)];
            }
            return [2 /*return*/, Promise.reject(response)];
        });
    });
});
export default axiosApiInstance;
