import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var RoundApi = /** @class */ (function () {
    function RoundApi() {
    }
    RoundApi.prototype.getRoundTeamSelect = function () {
        return http.get("/round/check_active_team_select");
    };
    RoundApi.prototype.getRoundTeamInput = function () {
        return http.get("/round/check_active_team_input");
    };
    RoundApi.prototype.getRoundTeamSelectPeriod = function () {
        return http.get("/round/current_team_select_period");
    };
    RoundApi.prototype.getAdditionalMatching = function () {
        return http.get("/round/active_additional_matching");
    };
    RoundApi.prototype.getRoundTeamInputPeriod = function () {
        return http.get("/round/current_team_input_period");
    };
    RoundApi = __decorate([
        injectable()
    ], RoundApi);
    return RoundApi;
}());
export default RoundApi;
