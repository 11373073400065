import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import TeamReps from "@/domain/repositories/TeamReps";
var MasterInteractor = /** @class */ (function () {
    function MasterInteractor() {
        this.repos = DIContainer.instance.get(TeamReps);
    }
    /* eslint-disable @typescript-eslint/camelcase */
    MasterInteractor.prototype.getTeamOwnedSimple = function () {
        return this.repos.getTeamOwnedSimple();
    };
    MasterInteractor.prototype.getTeamById = function (id) {
        return this.repos.getTeamById(id);
    };
    MasterInteractor.prototype.getTeams = function () {
        return this.repos.getTeams();
    };
    MasterInteractor.prototype.getTeamsMembers = function (params) {
        return this.repos.getTeamsMembers(params);
    };
    MasterInteractor.prototype.searchTeam = function (param) {
        return this.repos.searchTeam(param);
    };
    MasterInteractor.prototype.deleteTeam = function (team_id) {
        return this.repos.deleteTeam(team_id);
    };
    MasterInteractor.prototype.leaveTeam = function (team_id, account_id) {
        return this.repos.leaveTeam(team_id, account_id);
    };
    MasterInteractor.prototype.createTeam = function (team) {
        return this.repos.createTeam(team);
    };
    MasterInteractor.prototype.updateTeam = function (team) {
        return this.repos.updateTeam(team);
    };
    MasterInteractor.prototype.inviteMember = function (team_id, mail_address) {
        return this.repos.inviteMember(team_id, mail_address);
    };
    MasterInteractor.prototype.acceptJoinTeam = function (invite_token) {
        return this.repos.acceptJoinTeam(invite_token);
    };
    MasterInteractor.prototype.rejectJoinTeam = function (invite_token) {
        return this.repos.rejectJoinTeam(invite_token);
    };
    MasterInteractor.prototype.checkTokenInvited = function (invite_token) {
        return this.repos.checkTokenInvited(invite_token);
    };
    MasterInteractor.prototype.changeLeader = function (team_id, new_leader_account_id) {
        return this.repos.changeLeader(team_id, new_leader_account_id);
    };
    MasterInteractor.prototype.acceptChangeLeader = function (request_token) {
        return this.repos.acceptChangeLeader(request_token);
    };
    MasterInteractor.prototype.rejectChangeLeader = function (request_token) {
        return this.repos.rejectChangeLeader(request_token);
    };
    MasterInteractor.prototype.cancelChangeLeader = function (team_id) {
        return this.repos.cancelChangeLeader(team_id);
    };
    MasterInteractor.prototype.checkTokenRequested = function (request_token) {
        return this.repos.checkTokenRequested(request_token);
    };
    MasterInteractor.prototype.createSlackChannel = function () {
        return this.repos.createSlackChannel();
    };
    MasterInteractor.prototype.createPreSignedUrl = function (extension) {
        return this.repos.createPreSignedUrl(extension);
    };
    MasterInteractor.prototype.uploadToPreSignedUrl = function (preSignedUrl, file) {
        return this.repos.uploadToPreSignedUrl(preSignedUrl, file);
    };
    MasterInteractor = __decorate([
        injectable()
    ], MasterInteractor);
    return MasterInteractor;
}());
export default MasterInteractor;
