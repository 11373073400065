import { createStore } from "vuex";
import Project from "@/domain/entity/Project";
import CommonService from '@/services/CommonService';
import { getCurrentAccountId } from "@/commons/UserStatusUtis";
import RoundInteractor from "@/domain/usecases/RoundInteractor";
import DIContainer from "@/core/DIContainer";
var roundInteractor = DIContainer.instance.get(RoundInteractor);
var state = {
    organizationResgisterData: {},
    organizationInvitedResgisterData: {},
    userRegisterData: {},
    userData: {},
    organizationProfileInfo: {},
    callBackUrl: "",
    headerMode: "default",
    isLoggin: null,
    userProfileInfo: {},
    userProfileSkills: [],
    userProfileActivities: {},
    projectInfo: {},
    organizationProjectData: new Project,
    projectApply: {},
    createTeamData: {},
    createTeamCompleted: false,
    projectDetail: {
        totalApplicants: 0,
        totalQuesttian: 0
    },
    projectContractors: [],
    projectContractorStatus: null,
    organizationMenuInfo: {},
    userMenuInfo: {},
    userProfileId: 0,
    inviteTeamAlert: {},
    screen: "",
    categoryIdSearch: null,
    hideMenu: false,
    waitAuthCheck: false,
    prefectures: null,
    projectReleased: null,
    projectTempSaved: null,
    projectUnApproved: null,
    termsAgreed: false,
    deletedTeamAlert: null,
    teamInfo: null,
    projectDetailMatchingFilter: null,
    defaultErrorAlertStatus: true,
    areas: [
        { name: "北海道", ids: [1] },
        { name: "東北", ids: [2, 3, 4, 5, 6, 7] },
        { name: "南関東", ids: [11, 12, 13, 14] },
        { name: "北関東・甲信", ids: [8, 9, 10, 19, 20] },
        { name: "北陸", ids: [15, 16, 17, 18] },
        { name: "東海", ids: [21, 22, 23, 24] },
        { name: "近畿", ids: [25, 26, 27, 28, 29, 30] },
        { name: "中国", ids: [31, 32, 33, 34, 35] },
        { name: "四国", ids: [36, 37, 38, 39] },
        { name: "九州・沖縄", ids: [40, 41, 42, 43, 44, 45, 46, 47] },
    ],
    isDisabled: false,
    isDisabledTeams: false
};
var mutations = {
    setOrganizationResgisterData: function (state, data) {
        state.organizationResgisterData = data;
    },
    setOrganizationInvitedResgisterData: function (state, data) {
        state.organizationInvitedResgisterData = data;
    },
    setUserRegisterData: function (state, data) {
        state.userRegisterData = data;
    },
    setUserData: function (state, data) {
        state.userData = data;
    },
    setCallBackUrl: function (state, data) {
        state.callBackUrl = data;
    },
    setHeaderMode: function (state, data) {
        state.headerMode = data;
    },
    setIsloginStatus: function (state, data) {
        state.isLoggin = data;
    },
    setOrganizationProfileInfo: function (state, data) {
        state.organizationProfileInfo = data;
    },
    setUserProfileData: function (state, data) {
        state.userProfileInfo = data;
    },
    setUserProfileSkillsData: function (state, data) {
        state.userProfileSkills = data;
    },
    setUserProfileActivitiesData: function (state, data) {
        state.userProfileActivities = data;
    },
    setProjectInfo: function (state, data) {
        state.projectInfo = data;
    },
    setOrganizationProjectData: function (state, data) {
        state.organizationProjectData = data;
    },
    setProjectApply: function (state, data) {
        state.projectApply = data;
    },
    setProjectContractors: function (state, data) {
        state.projectContractors = data;
    },
    setProjectContractorStatus: function (state, value) {
        state.projectContractorStatus = value;
    },
    setCreateTeamData: function (state, data) {
        state.createTeamData = data;
    },
    setCreateTeamCompleted: function (state, data) {
        state.createTeamCompleted = data;
    },
    setApplicant: function (state, applicant) {
        state.projectDetail.totalApplicants = applicant;
    },
    setQuestion: function (state, totalQuesttian) {
        state.projectDetail.totalQuesttian = totalQuesttian;
    },
    setOrganizationMenuInfo: function (state, data) {
        state.organizationMenuInfo = data;
    },
    setUserMenuInfo: function (state, data) {
        state.userMenuInfo = data;
    },
    setUserProfileId: function (state, data) {
        state.userProfileId = data;
    },
    setInviteTeamAlert: function (state, data) {
        state.inviteTeamAlert = data;
    },
    checkReadGuideScreen: function (state, data) {
        state.screen = data;
    },
    setCategoryIdSearch: function (state, data) {
        state.categoryIdSearch = data;
    },
    setHidenMenu: function (state, data) {
        state.hideMenu = data;
    },
    setWaitAuthCheck: function (state, data) {
        state.waitAuthCheck = data;
    },
    setPrefectures: function (state, data) {
        state.prefectures = data;
    },
    setProjectReleased: function (state, id) {
        state.projectReleased = id;
    },
    setProjectTempSaved: function (state, id) {
        state.projectTempSaved = id;
    },
    setProjectUnApproved: function (state, id) {
        state.projectUnApproved = id;
    },
    setTermsAgreed: function (state, flag) {
        state.termsAgreed = flag;
    },
    setDeletedTeamAlert: function (state, data) {
        state.deletedTeamAlert = data;
    },
    setTeamInfo: function (state, data) {
        state.teamInfo = data;
    },
    setProjectDetailMatchingFilter: function (state, data) {
        state.projectDetailMatchingFilter = data;
    },
    setDefaultErrorAlertStatus: function (state, flag) {
        state.defaultErrorAlertStatus = flag;
    },
    setIsDisabled: function (state, flag) {
        state.isDisabled = flag;
    },
    setIsDisabledTeams: function (state, flag) {
        state.isDisabledTeams = flag;
    }
};
var actions = {
    detectProjectContractorStatus: function (ctx, contractors) {
        if (CommonService.isUser()) {
            var currentId = getCurrentAccountId();
            for (var _i = 0, contractors_1 = contractors; _i < contractors_1.length; _i++) {
                var team = contractors_1[_i];
                for (var _a = 0, _b = team.members; _a < _b.length; _a++) {
                    var member = _b[_a];
                    if (member.user_account_id === currentId) {
                        member.is_leader
                            ? ctx.commit('setProjectContractorStatus', 'LEADER')
                            : ctx.commit('setProjectContractorStatus', 'MEMBER');
                        ctx.commit('setTeamInfo', team);
                        return;
                    }
                }
            }
        }
        ctx.commit('setProjectContractorStatus', 'NOT_MEMBER');
    },
    checkActiveTeamSelect: function (_a) {
        var commit = _a.commit;
        return roundInteractor.getRoundTeamSelect().then(function (response) {
            commit('setIsDisabled', !response.is_team_select_time);
        });
    },
    checkActiveTeamInput: function (_a) {
        var commit = _a.commit;
        return roundInteractor.getRoundTeamInput().then(function (response) {
            commit('setIsDisabledTeams', !response.is_team_input_time);
        });
    },
};
export default createStore({
    state: state,
    mutations: mutations,
    actions: actions
});
