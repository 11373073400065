<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div class="modal-container">
          <div class="modal-body">
            <div class="modal-close" @click="$emit('close')" />
            <slot name="body">
              <div class="applicant_project_completed__container">
                <div class="applicant_project_completed__container-title" v-if="state.title" v-html="state.title">
                </div>
                <p class="applicant_project_completed__container-subtitle" v-if="state.content" v-html="state.content">
                </p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/alert/Alert.ts" />
<style lang="scss" scoped src="@/presentation/components/modals/alert/Alert.scss" />
