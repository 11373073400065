import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import AuthReps from "@/domain/repositories/AuthReps";
var AuthInteractor = /** @class */ (function () {
    function AuthInteractor() {
        this.repos = DIContainer.instance.get(AuthReps);
    }
    AuthInteractor.prototype.organizationLogin = function (email, password, remember_me) {
        return this.repos.organizationLogin(email, password, remember_me);
    };
    AuthInteractor.prototype.getAccountInfo = function () {
        return this.repos.getAccountInfo();
    };
    AuthInteractor.prototype.organizationSendMailResetPassword = function (email) {
        return this.repos.organizationSendMailResetPassword(email);
    };
    AuthInteractor.prototype.organizationValidateResetToken = function (reset_token) {
        return this.repos.organizationValidateResetToken(reset_token);
    };
    AuthInteractor.prototype.organizationResetPassword = function (reset_token, password, password_confirmation) {
        return this.repos.organizationResetPassword(reset_token, password, password_confirmation);
    };
    AuthInteractor.prototype.authCheck = function () {
        return this.repos.authCheck();
    };
    AuthInteractor.prototype.organizationLogout = function () {
        return this.repos.organizationLogout();
    };
    AuthInteractor.prototype.smsCheck = function () {
        return this.repos.smsCheck();
    };
    AuthInteractor = __decorate([
        injectable()
    ], AuthInteractor);
    return AuthInteractor;
}());
export default AuthInteractor;
