var ObjectUtil = /** @class */ (function () {
    function ObjectUtil() {
    }
    ObjectUtil.copyObject = function (object) {
        return JSON.parse(JSON.stringify(object));
    };
    ObjectUtil.prototype.isEmptyObject = function (object) {
        if (object === void 0) { object = {}; }
        return (Object.keys(object).length === 0 && object.constructor === Object);
    };
    return ObjectUtil;
}());
export default ObjectUtil;
