import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/presentation/router";
import store from "@/store";
createApp(App)
    .directive("click-outside", {
    beforeMount: function (el, binding, vnode) {
        var vm = binding.instance;
        var callback = binding.value;
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    }
})
    .use(router)
    .use(store)
    .mount("#app");
