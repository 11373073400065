import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var OrganizationApi = /** @class */ (function () {
    function OrganizationApi() {
    }
    OrganizationApi.prototype.getMyProfile = function () {
        return http.get("/organizations/profile");
    };
    OrganizationApi.prototype.getMyRating = function (page, limit) {
        return http.get("/organizations/rating", { page: page, limit: limit });
    };
    OrganizationApi.prototype.getMyProjects = function (params) {
        return http.get("/organizations/projects", params);
    };
    OrganizationApi.prototype.updateMyProfile = function (params) {
        return http.put("/organizations/profile/update", params);
    };
    OrganizationApi.prototype.getOrganProfile = function (accountId) {
        return http.get("/organizations/profile", { account_id: accountId });
    };
    OrganizationApi.prototype.getOrganRating = function (accountId, page, limit) {
        return http.get("/organizations/rating", { account_id: accountId, page: page, limit: limit });
    };
    OrganizationApi.prototype.getOrganProjects = function (params) {
        return http.get("/organizations/projects", params);
    };
    OrganizationApi.prototype.getOrganProfileMenu = function (accountId) {
        return http.get("/organizations/profile/menu", { account_id: accountId });
    };
    OrganizationApi.prototype.checkTutorial = function () {
        return http.get("/organizations/init/check");
    };
    OrganizationApi.prototype.readTutorial = function () {
        return http.post("/organizations/tutorial/read", {});
    };
    OrganizationApi.prototype.getOrganNgCorporates = function () {
        return http.get("/organizations/ng_corporates");
    };
    OrganizationApi.prototype.updateOrganNgCorporates = function (params) {
        return http.put("/organizations/ng_corporates/update", params);
    };
    OrganizationApi.prototype.getMembers = function () {
        return http.get("/organizations/members");
    };
    OrganizationApi.prototype.inviteMember = function (email) {
        return http.post("/organizations/register/invite", { email: email });
    };
    OrganizationApi.prototype.inviteComposition = function (email) {
        return http.post("/organizations/register/invite_composition", { email: email });
    };
    OrganizationApi.prototype.deleteOrganizationMember = function (organization_member_id) {
        return http.delete("/organizations/members/" + organization_member_id, {});
    };
    OrganizationApi.prototype.changeOrganizationOwner = function (organization_member_id) {
        return http.put("/organizations/change_owner", { organization_member_id: organization_member_id });
    };
    OrganizationApi.prototype.selectOrganization = function () {
        return http.get("/organizations/members/affiliation_organizations");
    };
    OrganizationApi.prototype.selectedOrganization = function (organization_id) {
        return http.post("/organizations/members/selected_organization", { organization_id: organization_id });
    };
    OrganizationApi.prototype.checkOwner = function (organization_id) {
        return http.get("/organizations/check_is_owner", { organization_id: organization_id });
    };
    OrganizationApi.prototype.getMultipleMatchingStatus = function (organization_id) {
        return http.get("/organizations/" + organization_id + "/multiple_matching_status");
    };
    OrganizationApi = __decorate([
        injectable()
    ], OrganizationApi);
    return OrganizationApi;
}());
export default OrganizationApi;
