import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var ProjectApi = /** @class */ (function () {
    function ProjectApi() {
    }
    ProjectApi.prototype.getProjectCategories = function () {
        return http.get("/projects/categories");
    };
    ProjectApi.prototype.searchProject = function (params) {
        return http.get("/projects/search", params);
    };
    ProjectApi.prototype.getCurrentUserProjectApplyInfo = function (project_id) {
        return http.get("/projects/" + project_id + "/apply");
    };
    ProjectApi.prototype.cancelProjectApplyCheck = function (project_id) {
        return http.post("/projects/" + project_id + "/apply_cancel", {});
    };
    ProjectApi.prototype.checkProjectApplyRequirement = function () {
        return http.post("/projects/apply_check", {});
    };
    ProjectApi.prototype.applyProject = function (projectId, data) {
        return http.post("/projects/" + projectId + "/apply", data);
    };
    ProjectApi = __decorate([
        injectable()
    ], ProjectApi);
    return ProjectApi;
}());
export default ProjectApi;
