import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var MilestoneApi = /** @class */ (function () {
    function MilestoneApi() {
    }
    MilestoneApi.prototype.getMilestones = function (team_id) {
        return http.get("/milestones", { team_id: team_id });
    };
    MilestoneApi.prototype.createMilestone = function (milestone) {
        return http.post("/milestones/create", milestone);
    };
    MilestoneApi.prototype.updateMilestone = function (id, milestone) {
        return http.put("/milestones/" + id, milestone);
    };
    MilestoneApi.prototype.updateMilestoneAndCreateQuestionnaire = function (id, milestone) {
        return http.post("/milestones/" + id + "/create_questionnaire", milestone);
    };
    MilestoneApi.prototype.updateMilestoneAndUpdateQuestionnaire = function (id, milestone) {
        return http.post("/milestones/" + id + "/update_questionnaire", milestone);
    };
    MilestoneApi.prototype.createMilestoneAndCreateQuestionnaire = function (milestone) {
        return http.post("/milestones/create_and_questionnaire", milestone);
    };
    MilestoneApi.prototype.deleteMilestoneFile = function (milestone_id, file_id) {
        return http.delete("/milestones/" + milestone_id + "/file/" + file_id + "/", {});
    };
    MilestoneApi = __decorate([
        injectable()
    ], MilestoneApi);
    return MilestoneApi;
}());
export default MilestoneApi;
