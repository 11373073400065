import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import RoundReps from "@/domain/repositories/RoundReps";
var RoundInteractor = /** @class */ (function () {
    function RoundInteractor() {
        this.repos = DIContainer.instance.get(RoundReps);
    }
    RoundInteractor.prototype.getRoundTeamSelect = function () {
        return this.repos.getRoundTeamSelect();
    };
    RoundInteractor.prototype.getRoundTeamInput = function () {
        return this.repos.getRoundTeamInput();
    };
    RoundInteractor.prototype.getRoundTeamSelectPeriod = function () {
        return this.repos.getRoundTeamSelectPeriod();
    };
    RoundInteractor.prototype.getAdditionalMatching = function () {
        return this.repos.getAdditionalMatching();
    };
    RoundInteractor.prototype.getRoundTeamInputPeriod = function () {
        return this.repos.getRoundTeamInputPeriod();
    };
    RoundInteractor = __decorate([
        injectable()
    ], RoundInteractor);
    return RoundInteractor;
}());
export default RoundInteractor;
