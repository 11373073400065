<template>
  <div class="signate-app">
    <Header />
    <div id="appContent" :class="{'change': state.footerMode}">
      <router-view />
    </div>
    <Footer v-if="state.footerMode"/>
    <FooterSimple v-else/>

    <Alert
      v-if="state.showAlertPopup == true"
      content="システムエラーが発生いたしました。大変ご迷惑をおかけし申し訳ございません。<br>お手数ですが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。<br>本画面が頻発して表示される場合は、大変お手数ですが、<br>運営事務局(contact_manabi-dxquest@a-brain.com)までお問い合わせください。"
      @close="state.showAlertPopup = false"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/presentation/components/header/Header.vue";
import Footer from "@/presentation/components/footer/Footer.vue";
import FooterSimple from "@/presentation/components/footer-simple/FooterSimple.vue";
import { useStore } from "vuex";
import CommonService from "@/services/CommonService";
import { reactive, onMounted } from "vue";
import DIContainer from "@/core/DIContainer";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import { useRouter } from "vue-router";
import RoutePath from './commons/RoutePath';
import { watchEffect } from 'vue';
import Alert from "@/presentation/components/modals/alert/Alert.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    FooterSimple,
    Alert
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
        isWhiteListAuthCheck: false,
        whiteList: [RoutePath.USER_REGISTER_PROFILE],
        footerMode: false,
        showAlertPopup: false,
        processAuthCheck: false
    });
    const authInteractor = DIContainer.instance.get(AuthInteractor);
    function checkWhiteList(url) {
        let flag = true;
        if (!url) {
            return flag;
        }
        state.isWhiteListAuthCheck;
        state.whiteList.map(path => {
            if (url.includes(path)) {
               flag = false;
            }
        })
        return flag;
    }
    router.beforeEach(function (to, from, next) {
        state.isWhiteListAuthCheck = checkWhiteList(to.path);
        next();
    });

    onMounted(() => {
      const isLogin = CommonService.checkLoginLocal();
      if (isLogin == false || state.isWhiteListAuthCheck) {
        state.processAuthCheck = true;
        authInteractor
          .authCheck()
          .then((result) => {
            state.processAuthCheck = false;
            if (
              result.data &&
              result.data.user &&
              (result.data.user.name || result.data.user.nick_name)
            ) {
              if (result.data.roles[0] == "user") {
                if (result.data.user.status == "SSO_REGISTERED") {
                  CommonService.deleteCookie("is_login");
                  CommonService.deleteCookie("user_roles");
                  CommonService.deleteCookie("user");
                  return;
                }
              }

              let account_name = result.data.user.nick_name
                ? result.data.user.nick_name
                : result.data.user.name;
              localStorage.setItem("accountName", account_name);
              store.commit("setIsloginStatus", true);
              let url = store.state.callBackUrl;
              if(url && store.state.waitAuthCheck)
              {
                store.commit("setWaitAuthCheck", false);
                router.push(url);
              }
            } else {
              CommonService.deleteCookie("is_login");
              CommonService.deleteCookie("user_roles");
              CommonService.deleteCookie("user");
              localStorage.removeItem("accountName");
              if(store.state.waitAuthCheck)
              {
                store.commit("setWaitAuthCheck", false);
                router.push(RoutePath.TOP);
              }
            }
          })
          .catch((error) => {
            state.processAuthCheck = false;
            CommonService.deleteCookie("is_login");
            CommonService.deleteCookie("user_roles");
            CommonService.deleteCookie("user");
            if(store.state.waitAuthCheck)
            {
              store.commit("setWaitAuthCheck", false);
              router.push(RoutePath.TOP);
            }
          });
      }

      window.addEventListener('alert-error', (event) => {
          if(store.state.defaultErrorAlertStatus){
            state.showAlertPopup = true;
          }
        });
    });

    router.afterEach(() => {
      state.footerMode = CommonService.configFooter();
    })

    return { state, store };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_responsive.scss";
@import "@/assets/scss/_valiables.scss";
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  body {
    margin: 0;
  }
  * {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 400;
    color: $text-color;
  }
  .signate-app {
    min-width: 1080px;
  }
  #appContent{
      min-height: calc(100vh - 217px);
      background: $bg-main-color;
      &.change {
        min-height: calc(100vh - 139px)!important;
      }
  }
  .white-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $white-color;
    z-index: 9999;
  }
}
@import "@/assets/scss/_styles.scss";
</style>
