import http from "@/data/datasource/api/Interceptors";
import ObjectUtil from "@/commons/ObjectUtil";
var objectUtil = new ObjectUtil();
export default {
    get: function (path, params, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.get(path, {
                headers: headers,
                params: params
            });
        }
        return http.get(path, { params: params });
    },
    post: function (path, params, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.post(path, params, {
                headers: headers
            });
        }
        return http.post(path, params);
    },
    put: function (path, params, body, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.put(path, body, {
                headers: headers,
                params: params
            });
        }
        return http.put(path, params);
    },
    delete: function (path, params, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.delete(path, {
                data: params,
                headers: headers
            });
        }
        return http.delete(path, { data: params });
    }
};
