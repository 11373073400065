var MyConfig = {};
// config from laravel .env
var env = window['laravelEnv'];
MyConfig.defaultRouter = env.defaultRouter;
MyConfig.host = env.host;
MyConfig.loginUrl = env.loginUrl;
MyConfig.signateLoginUrl = env.signateLoginUrl;
MyConfig.signateLogoutUrl = env.signateLogoutUrl;
MyConfig.registerUrl = env.registerUrl;
MyConfig.reCapcha_sitekey = env.reCaptchaSiteKey;
MyConfig.language = env.language;
MyConfig.signateProfile = env.signateProfile;
MyConfig.signatePolicy = env.signatePolicy;
MyConfig.signateCompetitionProfile = env.signateCompetitionProfile;
MyConfig.questSignateAccount = env.questSignateAccount;
MyConfig.kaggleDomain = env.kaggleDomain;
MyConfig.youtubeIfame = env.youtubeIfame;
MyConfig.youtube = env.youtube;
MyConfig.contactLink = env.contactLink;
MyConfig.corporateNumberNoWorkPlace = env.corporateNumberNoWorkPlace;
export default MyConfig;
