import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var QuestionnaireApi = /** @class */ (function () {
    function QuestionnaireApi() {
    }
    QuestionnaireApi.prototype.isQuestionnaireAnswered = function (team_id) {
        return http.get("/questionnaire/get_answerd", { team_id: team_id });
    };
    QuestionnaireApi = __decorate([
        injectable()
    ], QuestionnaireApi);
    return QuestionnaireApi;
}());
export default QuestionnaireApi;
