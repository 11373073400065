import CommonService from '@/services/CommonService';
export var getCurrentAccountId = function () {
    var _a;
    var user = CommonService.getCookie("user");
    return user ? (_a = JSON.parse(decodeURIComponent(user))) === null || _a === void 0 ? void 0 : _a.account_id : null;
};
export var getCurrentOrganizationId = function () {
    var _a;
    var user = CommonService.getCookie("user");
    return user ? (_a = JSON.parse(decodeURIComponent(user))) === null || _a === void 0 ? void 0 : _a.organization_id : null;
};
