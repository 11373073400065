import MyConfig from "@/_config";
import http from "@/data/datasource/api/Interceptors";
import ObjectUtil from "@/commons/ObjectUtil";
var objectUtil = new ObjectUtil();
export default {
    baseUrl: function () {
        return MyConfig.host;
    },
    get: function (path, params, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.get(this.baseUrl() + path, {
                headers: headers,
                params: params
            });
        }
        return http.get(this.baseUrl() + path, { params: params });
    },
    post: function (path, params, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.post(this.baseUrl() + path, params, {
                headers: headers
            });
        }
        return http.post(this.baseUrl() + path, params);
    },
    put: function (path, params, body, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.put(this.baseUrl() + path, body, {
                headers: headers,
                params: params
            });
        }
        return http.put(this.baseUrl() + path, params);
    },
    delete: function (path, params, headers) {
        if (!objectUtil.isEmptyObject(headers)) {
            return http.delete(this.baseUrl() + path, {
                data: params,
                headers: headers
            });
        }
        return http.delete(this.baseUrl() + path, { data: params });
    }
};
