import MessagesData from "@/assets/json/Messages.json";
import MyConfig from "@/_config";
var LanguageUtil = /** @class */ (function () {
    function LanguageUtil() {
        var _this = this;
        this.messages = MessagesData.messages;
        this._lang = [];
        if (MyConfig.language === "ja") {
            this.messages.map(function (item) {
                _this._lang[item[0]] = item[1];
            });
        }
        else {
            this.messages.map(function (item) {
                _this._lang[item[0]] = item[2];
            });
        }
    }
    LanguageUtil.getInstance = function () {
        if (!this._instance) {
            this._instance = new LanguageUtil();
        }
        return this._instance;
    };
    Object.defineProperty(LanguageUtil.prototype, "lang", {
        get: function () {
            return this._lang;
        },
        enumerable: false,
        configurable: true
    });
    return LanguageUtil;
}());
export default LanguageUtil;
