import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
export default defineComponent({
    name: "Footer",
    setup: function () {
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang
        });
        var nowYear = new Date().getFullYear();
        function scrollTop() {
            window.scroll({
                top: 0,
                behavior: "smooth"
            });
        }
        return {
            state: state,
            nowYear: nowYear,
            scrollTop: scrollTop,
        };
    }
});
