import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import OrganizationReps from "@/domain/repositories/OrganizationReps";
var OrganizationInteractor = /** @class */ (function () {
    function OrganizationInteractor() {
        this.repos = DIContainer.instance.get(OrganizationReps);
    }
    /* eslint-disable @typescript-eslint/camelcase */
    OrganizationInteractor.prototype.getMyProfile = function () {
        return this.repos.getMyProfile();
    };
    OrganizationInteractor.prototype.getMyRating = function (page, limit) {
        return this.repos.getMyRating(page, limit);
    };
    OrganizationInteractor.prototype.getMyProjects = function (params) {
        return this.repos.getMyProjects(params);
    };
    OrganizationInteractor.prototype.updateMyProfile = function (params) {
        return this.repos.updateMyProfile(params);
    };
    OrganizationInteractor.prototype.getOrganProfile = function (accoundId) {
        return this.repos.getOrganProfile(accoundId);
    };
    OrganizationInteractor.prototype.getOrganRating = function (accountId, page, limit) {
        return this.repos.getOrganRating(accountId, page, limit);
    };
    OrganizationInteractor.prototype.getOrganProjects = function (params) {
        return this.repos.getOrganProjects(params);
    };
    OrganizationInteractor.prototype.getOrganProfileMenu = function (accoundId) {
        return this.repos.getOrganProfileMenu(accoundId);
    };
    OrganizationInteractor.prototype.checkTutorial = function () {
        return this.repos.checkTutorial();
    };
    OrganizationInteractor.prototype.readTutorial = function () {
        return this.repos.readTutorial();
    };
    OrganizationInteractor.prototype.getOrganNgCorporates = function () {
        return this.repos.getOrganNgCorporates();
    };
    OrganizationInteractor.prototype.updateOrganNgCorporates = function (params) {
        return this.repos.updateOrganNgCorporates(params);
    };
    OrganizationInteractor.prototype.getMembers = function () {
        return this.repos.getMembers();
    };
    OrganizationInteractor.prototype.inviteMember = function (email) {
        return this.repos.inviteMember(email);
    };
    OrganizationInteractor.prototype.deleteOrganizationMember = function (organization_member_id) {
        return this.repos.deleteOrganizationMember(organization_member_id);
    };
    OrganizationInteractor.prototype.changeOrganizationOwner = function (organization_member_id) {
        return this.repos.changeOrganizationOwner(organization_member_id);
    };
    OrganizationInteractor.prototype.inviteComposition = function (email) {
        return this.repos.inviteComposition(email);
    };
    OrganizationInteractor.prototype.selectOrganization = function () {
        return this.repos.selectOrganization();
    };
    OrganizationInteractor.prototype.selectedOrganization = function (organization_id) {
        return this.repos.selectedOrganization(organization_id);
    };
    OrganizationInteractor.prototype.checkOwner = function (organization_id) {
        return this.repos.checkOwner(organization_id);
    };
    OrganizationInteractor.prototype.getMultipleMatchingStatus = function (organization_id) {
        return this.repos.getMultipleMatchingStatus(organization_id);
    };
    OrganizationInteractor = __decorate([
        injectable()
    ], OrganizationInteractor);
    return OrganizationInteractor;
}());
export default OrganizationInteractor;
