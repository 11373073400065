import "reflect-metadata";
import { Container } from "inversify";
// datasource
import AuthApi from "@/data/datasource/api/AuthApi";
import OrganizationRegisterApi from "@/data/datasource/api/OrganizationRegisterApi";
import UserApi from "@/data/datasource/api/UserApi";
import MasterApi from "@/data/datasource/api/MasterApi";
import OrganizationProjectApi from "@/data/datasource/api/OrganizationProjectApi";
import OrganizationApi from "@/data/datasource/api/OrganizationApi";
import ProjectApi from "@/data/datasource/api/ProjectApi";
import TeamApi from "@/data/datasource/api/TeamApi";
import MilestoneApi from "@/data/datasource/api/MilestoneApi";
import DeliverableApi from "@/data/datasource/api/DeliverableApi";
import QuestionnaireApi from "@/data/datasource/api/QuestionnaireApi";
import RoundApi from "@/data/datasource/api/RoundApi";
// respository
import AuthReps from "@/domain/repositories/AuthReps";
import OrganizationRegisterReps from "@/domain/repositories/OrganizationRegisterReps";
import UserReps from "@/domain/repositories/UserReps";
import MasterReps from "@/domain/repositories/MasterReps";
import OrganizationReps from "@/domain/repositories/OrganizationReps";
import ProjectReps from "@/domain/repositories/ProjectReps";
import TeamReps from "@/domain/repositories/TeamReps";
import OrganizationProjectReps from "@/domain/repositories/OrganizationProjectReps";
import MilestoneReps from "@/domain/repositories/MilestoneReps";
import DeliverableReps from "@/domain/repositories/DeliverableReps";
import QuestionnaireReps from "@/domain/repositories/QuestionnaireReps";
import RoundReps from "@/domain/repositories/RoundReps";
// usecase
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import OrganizationRegisteInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import UserInteractor from "@/domain/usecases/UserInteractor";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import MilestoneInteractor from "@/domain/usecases/MilestoneInteractor";
import DeliverableInteractor from "@/domain/usecases/DeliverableInteractor";
import QuestionnaireInteractor from "@/domain/usecases/QuestionnaireInteractor";
import RoundInteractor from "@/domain/usecases/RoundInteractor";
var DIContainer = /** @class */ (function () {
    function DIContainer() {
        this.container = new Container();
        this.register();
    }
    DIContainer.prototype.register = function () {
        // datasource
        this.container.bind(AuthApi).to(AuthApi).inRequestScope();
        this.container.bind(UserApi).to(UserApi).inRequestScope();
        this.container.bind(MasterApi).to(MasterApi).inRequestScope();
        this.container.bind(OrganizationRegisterApi).to(OrganizationRegisterApi).inRequestScope();
        this.container.bind(OrganizationProjectApi).to(OrganizationProjectApi).inRequestScope();
        this.container.bind(OrganizationApi).to(OrganizationApi).inRequestScope();
        this.container.bind(ProjectApi).to(ProjectApi).inRequestScope();
        this.container.bind(TeamApi).to(TeamApi).inRequestScope();
        this.container.bind(MilestoneApi).to(MilestoneApi).inRequestScope();
        this.container.bind(DeliverableApi).to(DeliverableApi).inRequestScope();
        this.container.bind(QuestionnaireApi).to(QuestionnaireApi).inRequestScope();
        this.container.bind(RoundApi).to(RoundApi).inRequestScope();
        // repository
        this.container.bind(AuthReps).to(AuthApi).inRequestScope();
        this.container.bind(UserReps).to(UserApi).inRequestScope();
        this.container.bind(MasterReps).to(MasterApi).inRequestScope();
        this.container.bind(OrganizationRegisterReps).to(OrganizationRegisterApi).inRequestScope();
        this.container.bind(OrganizationProjectReps).to(OrganizationProjectApi).inRequestScope();
        this.container.bind(OrganizationReps).to(OrganizationApi).inRequestScope();
        this.container.bind(ProjectReps).to(ProjectApi).inRequestScope();
        this.container.bind(TeamReps).to(TeamApi).inRequestScope();
        this.container.bind(MilestoneReps).to(MilestoneApi).inRequestScope();
        this.container.bind(DeliverableReps).to(DeliverableApi).inRequestScope();
        this.container.bind(QuestionnaireReps).to(QuestionnaireApi).inRequestScope();
        this.container.bind(RoundReps).to(RoundApi).inRequestScope();
        // usecase
        this.container.bind(AuthInteractor).to(AuthInteractor).inRequestScope();
        this.container.bind(UserInteractor).to(UserInteractor).inRequestScope();
        this.container.bind(MasterInteractor).to(MasterInteractor).inRequestScope();
        this.container.bind(OrganizationRegisteInteractor).to(OrganizationRegisteInteractor).inRequestScope();
        this.container.bind(OrganizationProjectInteractor).to(OrganizationProjectInteractor).inRequestScope();
        this.container.bind(OrganizationInteractor).to(OrganizationInteractor).inRequestScope();
        this.container.bind(ProjectInteractor).to(ProjectInteractor).inRequestScope();
        this.container.bind(TeamInteractor).to(TeamInteractor).inRequestScope();
        this.container.bind(MilestoneInteractor).to(MilestoneInteractor).inRequestScope();
        this.container.bind(DeliverableInteractor).to(DeliverableInteractor).inRequestScope();
        this.container.bind(QuestionnaireInteractor).to(QuestionnaireInteractor).inRequestScope();
        this.container.bind(RoundInteractor).to(RoundInteractor).inRequestScope();
    };
    Object.defineProperty(DIContainer, "instance", {
        get: function () {
            if (!this._instance) {
                this._instance = new DIContainer();
            }
            return this._instance;
        },
        enumerable: false,
        configurable: true
    });
    DIContainer.prototype.get = function (serviceIdentifier) {
        return this.container.get(serviceIdentifier);
    };
    return DIContainer;
}());
export default DIContainer;
