var CommonService = /** @class */ (function () {
    function CommonService() {
    }
    CommonService.configHeader = function () {
        var is_login = this.getCookie("is_login");
        var user_roles = this.getCookie("user_roles");
        if (is_login == "1" && user_roles.includes("user")) {
            return "userLogged";
        }
        else if (is_login == "1" && user_roles.includes("organization")) {
            return "organizationLogged";
        }
        else {
            return "default";
        }
    };
    CommonService.getName = function () {
        var is_login = this.getCookie("is_login");
        var user = localStorage.getItem("accountName");
        if (is_login == "1" && user) {
            return user;
        }
        return "";
    };
    CommonService.isComposition = function () {
        var is_login = this.getCookie("is_login");
        var is_composition = localStorage.getItem("isCompositon");
        if (is_login == "1" && is_composition == "true") {
            return true;
        }
        else {
            return false;
        }
    };
    CommonService.getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };
    CommonService.checkLoginLocal = function () {
        var is_login = this.getCookie("is_login");
        if (!is_login) {
            return false;
        }
        return true;
    };
    CommonService.isUser = function () {
        if (!this.checkLoginLocal()) {
            return false;
        }
        var user_roles = this.getCookie("user_roles");
        if (user_roles.includes("user")) {
            return true;
        }
        return false;
    };
    CommonService.isOrganization = function () {
        if (!this.checkLoginLocal()) {
            return false;
        }
        var user_roles = this.getCookie("user_roles");
        if (user_roles.includes("organization")) {
            return true;
        }
        return false;
    };
    CommonService.configFooter = function () {
        var pathname = window.location.pathname;
        var routeUseFooterSimple = [
        // "/user/register/",
        // "/organization/register/"
        ];
        var check = false;
        routeUseFooterSimple.forEach(function (route) {
            if (pathname.includes(route)) {
                check = true;
            }
        });
        return check;
    };
    CommonService.deleteCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                document.cookie = name + "; expires=" + new Date().toUTCString() + "; path=/;";
                break;
            }
        }
        return;
    };
    CommonService.verifyCallbackReCapcha = function () {
        if (grecaptcha == undefined) {
            return "";
        }
        try {
            var v = grecaptcha.getResponse();
            if (v.length > 0) {
                return v;
            }
            else {
                return "";
            }
        }
        catch (error) {
            return "";
        }
    };
    CommonService.setPerPageNumber = function (number) {
        localStorage.setItem("projectPerPage", number);
    };
    CommonService.getPerPageNumber = function () {
        var number = localStorage.getItem("projectPerPage") ? localStorage.getItem("projectPerPage") : 10;
        return Number(number);
    };
    CommonService.mapLanguageName = function (code) {
        if (!code) {
            return;
        }
        switch (code) {
            case "JA" || 'ja':
                return "日本語";
                break;
            case "EN" || 'en':
                return "英語";
                break;
            case "ZH" || 'zh':
                return "中国語";
                break;
            default:
                "";
                break;
        }
    };
    return CommonService;
}());
export default CommonService;
