import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import MasterReps from "@/domain/repositories/MasterReps";
var MasterInteractor = /** @class */ (function () {
    function MasterInteractor() {
        this.repos = DIContainer.instance.get(MasterReps);
    }
    /* eslint-disable @typescript-eslint/camelcase */
    MasterInteractor.prototype.getAllPerfecture = function () {
        return this.repos.getAllPerfecture();
    };
    MasterInteractor.prototype.getAllCitiesByPerfecture = function (id) {
        return this.repos.getAllCitiesByPerfecture(id);
    };
    MasterInteractor.prototype.searchCorporations = function (keyword) {
        return this.repos.searchCorporations(keyword);
    };
    MasterInteractor.prototype.getOneCorporations = function (corporateNumber) {
        return this.repos.getOneCorporations(corporateNumber);
    };
    MasterInteractor.prototype.getSkills = function (param) {
        return this.repos.getSkills(param);
    };
    MasterInteractor.prototype.getIndustries = function () {
        return this.repos.getIndustries();
    };
    MasterInteractor.prototype.getOccupations = function () {
        return this.repos.getOccupations();
    };
    MasterInteractor.prototype.getAvailableDatetimes = function () {
        return this.repos.getAvailableDatetimes();
    };
    MasterInteractor.prototype.getDataTypes = function () {
        return this.repos.getDataTypes();
    };
    MasterInteractor.prototype.uploadFile = function (type, id, img_base64) {
        return this.repos.uploadFile(type, id, img_base64);
    };
    MasterInteractor = __decorate([
        injectable()
    ], MasterInteractor);
    return MasterInteractor;
}());
export default MasterInteractor;
