import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import UserReps from "@/domain/repositories/UserReps";
var UserInteractor = /** @class */ (function () {
    function UserInteractor() {
        this.repos = DIContainer.instance.get(UserReps);
    }
    UserInteractor.prototype.registerValidate = function (user) {
        return this.repos.registerValidate(user);
    };
    UserInteractor.prototype.updateInforUser = function (user) {
        return this.repos.updateInforUser(user);
    };
    UserInteractor.prototype.logout = function () {
        return this.repos.logout();
    };
    UserInteractor.prototype.getUserAccountInfo = function () {
        return this.repos.getUserAccountInfo();
    };
    UserInteractor.prototype.getUserProfile = function (account_id) {
        return this.repos.getUserProfile(account_id);
    };
    UserInteractor.prototype.updateUserProfile = function (user) {
        return this.repos.updateUserProfile(user);
    };
    UserInteractor.prototype.getPreSignedUrlPr = function () {
        return this.repos.getPreSignedUrlPr();
    };
    UserInteractor.prototype.deleteFilesUserPr = function () {
        return this.repos.deleteFilesUserPr();
    };
    UserInteractor.prototype.getUserProfileTeam = function (account_id) {
        return this.repos.getUserProfileTeam(account_id);
    };
    UserInteractor.prototype.getUserProfileSkills = function (account_id) {
        return this.repos.getUserProfileSkills(account_id);
    };
    UserInteractor.prototype.updateUserProfileSkills = function (skill) {
        return this.repos.updateUserProfileSkills(skill);
    };
    UserInteractor.prototype.getUserProfileActivities = function (account_id) {
        return this.repos.getUserProfileActivities(account_id);
    };
    UserInteractor.prototype.updateUserProfileActivities = function (activities) {
        return this.repos.updateUserProfileActivities(activities);
    };
    UserInteractor.prototype.getMyRating = function (account_id, page, limit) {
        return this.repos.getMyRating(account_id, page, limit);
    };
    UserInteractor.prototype.getListUserProject = function (param) {
        return this.repos.getListUserProjects(param);
    };
    UserInteractor.prototype.getUserProfileMenu = function (account_id) {
        return this.repos.getUserProfileMenu(account_id);
    };
    UserInteractor.prototype.checkTutorial = function () {
        return this.repos.checkTutorial();
    };
    UserInteractor.prototype.readTutorial = function () {
        return this.repos.readTutorial();
    };
    UserInteractor.prototype.userInitCheck = function () {
        return this.repos.userInitCheck();
    };
    UserInteractor.prototype.userSmsRead = function () {
        return this.repos.userSmsRead();
    };
    UserInteractor.prototype.userTeamInvitation = function () {
        return this.repos.userTeamInvitation();
    };
    UserInteractor = __decorate([
        injectable()
    ], UserInteractor);
    return UserInteractor;
}());
export default UserInteractor;
