<template>
  <div class="footer">
    <a
      href="javascript:void(0)"
      @click="scrollTop"
      alt="Go to top"
      class="footer__scroll-up"
    >
      <i class="far fa-chevron-up"></i>
    </a>
    <div class="logo">
      <img src="@/assets/images/layout/logo_footer.svg" alt="" class="footer-image" />
      <p class="copyright"><span>©</span> 2018 - {{nowYear}} SIGNATE Inc.</p>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/footer/Footer.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/footer/Footer.scss" scoped>
</style>
