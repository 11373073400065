import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var OrganizationRegisterApi = /** @class */ (function () {
    function OrganizationRegisterApi() {
    }
    /* eslint-disable @typescript-eslint/camelcase */
    OrganizationRegisterApi.prototype.validate = function (email, password, password_confirmation, recaptcha_token) {
        return http.post("/organizations/register/validate_account", {
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            recaptcha_token: recaptcha_token
        });
    };
    OrganizationRegisterApi.prototype.register = function (organization) {
        return http.post("/organizations/register", organization);
    };
    OrganizationRegisterApi.prototype.resendEmail = function (organization_member_id, organization_id) {
        return http.post("/organizations/register/send_mail", {
            organization_member_id: organization_member_id,
            organization_id: organization_id
        });
    };
    OrganizationRegisterApi.prototype.validateTokenRegister = function (register_token, organization_member_id, organization_id) {
        return http.post("/organizations/register/validate_token", {
            register_token: register_token,
            organization_member_id: organization_member_id,
            organization_id: organization_id
        });
    };
    OrganizationRegisterApi.prototype.validateInvite = function (register_token, organization_member_id, organization_id) {
        return http.get("/organizations/register/validate_invite", {
            register_token: register_token,
            organization_member_id: organization_member_id,
            organization_id: organization_id
        });
    };
    OrganizationRegisterApi.prototype.validateInviteAccount = function (email, password, password_confirmation, recaptcha_token) {
        return http.post("/organizations/register/validate_invite_account", {
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            recaptcha_token: recaptcha_token
        });
    };
    OrganizationRegisterApi.prototype.registerInvited = function (organization_member) {
        return http.put("/organizations/register/invite_member", organization_member);
    };
    OrganizationRegisterApi.prototype.validateInviteComposition = function (register_token, organization_member_id, organization_id) {
        return http.get("/organizations/register/validate_invite_composition", {
            register_token: register_token,
            organization_member_id: organization_member_id,
            organization_id: organization_id
        });
    };
    OrganizationRegisterApi.prototype.acceptInviteComposition = function (organization_member_id, organization_id) {
        return http.post("/organizations/register/accept_invite_composition", {
            organization_member_id: organization_member_id,
            organization_id: organization_id
        });
    };
    OrganizationRegisterApi.prototype.rejectInviteComposition = function (organization_member_id, organization_id) {
        return http.post("/organizations/register/reject_invite_composition", {
            organization_member_id: organization_member_id,
            organization_id: organization_id
        });
    };
    OrganizationRegisterApi = __decorate([
        injectable()
    ], OrganizationRegisterApi);
    return OrganizationRegisterApi;
}());
export default OrganizationRegisterApi;
