import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import OrganizationProjectReps from "@/domain/repositories/OrganizationProjectReps";
var OrganizationProjectInteractor = /** @class */ (function () {
    function OrganizationProjectInteractor() {
        this.repos = DIContainer.instance.get(OrganizationProjectReps);
    }
    OrganizationProjectInteractor.prototype.getListOrganizationProject = function (param) {
        return this.repos.getListOrganizationProjects(param);
    };
    OrganizationProjectInteractor.prototype.getOrganizationProjectById = function (projectId) {
        return this.repos.getOrganizationProjectById(projectId);
    };
    OrganizationProjectInteractor.prototype.getOrganizationProjectApplicants = function (projectId, keyword, orderColumn, orderType, limit, page, filterStatus, filterHidden) {
        if (keyword === void 0) { keyword = null; }
        return this.repos.getOrganizationProjectApplicants(projectId, keyword, orderColumn, orderType, limit, page, filterStatus, filterHidden);
    };
    OrganizationProjectInteractor.prototype.getOrganizationProjectAssignable = function (projectId, data) {
        return this.repos.getOrganizationProjectAssignable(projectId, data);
    };
    OrganizationProjectInteractor.prototype.updateOrganizationProjectAssign = function (projectId, data) {
        return this.repos.updateOrganizationProjectAssign(projectId, data);
    };
    OrganizationProjectInteractor.prototype.updateOrganizationProjectApplicant = function (applicantId, data) {
        return this.repos.updateOrganizationProjectApplicant(applicantId, data);
    };
    OrganizationProjectInteractor.prototype.getOrganizationProjectQuestion = function (params) {
        return this.repos.getOrganizationProjectQuestion(params);
    };
    OrganizationProjectInteractor.prototype.postOrganizationProjectQuestion = function (data) {
        return this.repos.postOrganizationProjectQuestion(data);
    };
    OrganizationProjectInteractor.prototype.postOrganizationProjectQuestionResponse = function (data) {
        return this.repos.postOrganizationProjectQuestionResponse(data);
    };
    OrganizationProjectInteractor.prototype.getDetailApplicant = function (applicant_id) {
        return this.repos.getDetailApplicant(applicant_id);
    };
    OrganizationProjectInteractor.prototype.updateOrganizationProject = function (projectId, data) {
        return this.repos.updateOrganizationProject(projectId, data);
    };
    OrganizationProjectInteractor.prototype.getContractor = function (project_id) {
        return this.repos.getContractor(project_id);
    };
    OrganizationProjectInteractor.prototype.getContractors = function (project_id) {
        return this.repos.getContractors(project_id);
    };
    OrganizationProjectInteractor.prototype.rating = function (applicant_id, rating_point, rating_comment) {
        return this.repos.rating(applicant_id, rating_point, rating_comment);
    };
    OrganizationProjectInteractor.prototype.ratingOrganize = function (project_id, rating_point, rating_comment) {
        return this.repos.ratingOrganize(project_id, rating_point, rating_comment);
    };
    OrganizationProjectInteractor.prototype.checkOwner = function (organization_id) {
        return this.repos.checkOwner(organization_id);
    };
    OrganizationProjectInteractor.prototype.creatProject = function (project) {
        return this.repos.creatProject(project);
    };
    OrganizationProjectInteractor.prototype.requestSMS = function (data) {
        return this.repos.requestSMS(data);
    };
    OrganizationProjectInteractor.prototype.authorizeSMS = function (data) {
        return this.repos.authorizeSMS(data);
    };
    OrganizationProjectInteractor.prototype.getApplicantsCount = function (project_id) {
        return this.repos.getApplicantsCount(project_id);
    };
    OrganizationProjectInteractor = __decorate([
        injectable()
    ], OrganizationProjectInteractor);
    return OrganizationProjectInteractor;
}());
export default OrganizationProjectInteractor;
