import { __awaiter, __generator } from "tslib";
import { createRouter, createWebHistory } from "vue-router";
import CommonService from "@/services/CommonService";
import RoutePath from '@/commons/RoutePath';
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import DIContainer from "@/core/DIContainer";
import store from "@/store/index";
//Auth
var Login = function () {
    return import("@/presentation/views/login/Login.vue");
};
//user register
var UserRegister = function () { return import("@/presentation/views/user/register/Register.vue"); };
var UserRegisterProfile = function () {
    return import("@/presentation/views/user/register/profile/Profile.vue");
};
var UserRegisterConfirm = function () {
    return import("@/presentation/views/user/register/confirm/Confirm.vue");
};
var UserRegisterCompleted = function () {
    return import("@/presentation/views/user/register/completed/Completed.vue");
};
// constant
var ALLOW_ALL = "allow-all";
var NOT_LOGGIN = "not-loggin";
var FOR_USER = "for-user";
var FOR_ORGANIZATION = "for-organization";
var authInteractor = DIContainer.instance.get(AuthInteractor);
var LoginPage = function () { return import("@/presentation/views/login/Login.vue"); };
//organization login
var OrganizationLogin = function () {
    return import("@/presentation/views/organization/login/OrganizationLogin.vue");
};
var OrganizationResetPassword = function () {
    return import("@/presentation/views/organization/reset-password/OrganizationResetPassword.vue");
};
var OrganizationResetPasswordSuccess = function () {
    return import("@/presentation/views/organization/reset-password-success/OrganizationResetPasswordSuccess.vue");
};
var OrganizationResetPasswordFail = function () {
    return import("@/presentation/views/organization/reset-password-expired/OrganizationResetPasswordExpired.vue");
};
//organization Register
var OrganizationRegister = function () {
    return import("@/presentation/views/organization/register/Register.vue");
};
var OrganizationRegisterProfile = function () {
    return import("@/presentation/views/organization/register/profile/Profile.vue");
};
var MyProfile = function () {
    return import("@/presentation/views/organization/my-profile/MyProfile.vue");
};
var OrganizationDetailProfile = function () {
    return import("@/presentation/views/organization/my-profile/info/Info.vue");
};
var OrganizationRecruitting = function () {
    return import("@/presentation/views/organization/my-profile/recruiting/Recruiting.vue");
};
var OrganizationMember = function () {
    return import("@/presentation/views/organization/my-profile/member/Member.vue");
};
var OrganizationRegisterSendMail = function () {
    return import("@/presentation/views/organization/register/send-mail/SendMail.vue");
};
var OrganizationRegisterDetailProfile = function () {
    return import("@/presentation/views/organization/register/detail-profile/DetailProfile.vue");
};
var OrganizationRegisterConfirm = function () {
    return import("@/presentation/views/organization/register/confirm/Confirm.vue");
};
var OrganizationRegisterCompleted = function () {
    return import("@/presentation/views/organization/register/completed/Completed.vue");
};
var OrganizationRegisterExpired = function () {
    return import("@/presentation/views/organization/register/expired/Expired.vue");
};
var OrganizationRegisterInvalidUrl = function () {
    return import("@/presentation/views/organization/register/invalid-url/InvalidUrl.vue");
};
var OrganizationRegisterInvited = function () {
    return import("@/presentation/views/organization/register-invited/Register.vue");
};
var OrganizationRegisterInvitedProfile = function () {
    return import("@/presentation/views/organization/register-invited/profile/Profile.vue");
};
var OrganizationRegisterInvitedDetailProfile = function () {
    return import("@/presentation/views/organization/register-invited/detail-profile/DetailProfile.vue");
};
var OrganizationRegisterInvitedConfirm = function () {
    return import("@/presentation/views/organization/register-invited/confirm/Confirm.vue");
};
var OrganizationRegisterInvitedCompleted = function () {
    return import("@/presentation/views/organization/register-invited/completed/Completed.vue");
};
var OrganizationRegisterInvitedExpired = function () {
    return import("@/presentation/views/organization/register-invited/expired/Expired.vue");
};
var OrganizationRegisterInvitedInvalidUrl = function () {
    return import("@/presentation/views/organization/register-invited/invalid-url/InvalidUrl.vue");
};
var OrganizationRegisterInvitedCompositionConfirm = function () {
    return import("@/presentation/views/organization/register-invited/composition-confirm/CompositionConfirm.vue");
};
// organization dashboard
var OrganizationDashboard = function () {
    return import("@/presentation/views/organization/dashboard/Dashboard.vue");
};
var OrganizationDashboardListProjects = function () {
    return import("@/presentation/views/organization/dashboard/list-projects/ListProjects.vue");
};
var OrganizationDashboardListIncentives = function () {
    return import("@/presentation/views/organization/dashboard/list-incentives/ListIncentives.vue");
};
var OrganizationDashboardCreateProject = function () {
    return import("@/presentation/views/organization/dashboard/create-project/CreateProject.vue");
};
var OrganizationDashboardConfirmProject = function () {
    return import("@/presentation/views/organization/dashboard/confirm-project/ConfirmProject.vue");
};
// change organization
var SelectOrganization = function () {
    return import("@/presentation/views/organization/select-organization/SelectOrganization.vue");
};
// user dashboard
var UserDashboard = function () {
    return import("@/presentation/views/user/dashboard/Dashboard.vue");
};
var UserDashboardProjects = function () {
    return import("@/presentation/views/user/dashboard/list-projects/ListProjects.vue");
};
var UserDashboardIncentives = function () {
    return import("@/presentation/views/user/dashboard/list-incentives/ListIncentives.vue");
};
var PageNotFound = function () {
    return import("@/presentation/views/pages/404/404.vue");
};
var InvalidAccount = function () {
    return import("@/presentation/views/pages/invalid/invalid.vue");
};
var OutsideRegistration = function () {
    return import("@/presentation/views/pages/outside-registration/OutsideRegistration.vue");
};
// user profile
var UserProfile = function () {
    return import("@/presentation/views/user/profile/Profile.vue");
};
var UserProfileInfo = function () {
    return import("@/presentation/views/user/profile/info/Info.vue");
};
var UserProfileTeam = function () {
    return import("@/presentation/views/user/profile/team/Team.vue");
};
var UserProfileSkill = function () {
    return import("@/presentation/views/user/profile/skill/Skill.vue");
};
var UserProfileActivity = function () {
    return import("@/presentation/views/user/profile/activity/Activity.vue");
};
var UserProfileService = function () {
    return import("@/presentation/views/user/profile/service/Service.vue");
};
// my profile
var OrganizationMyProfileEditProject = function () {
    return import("@/presentation/views/organization/my-profile/edit-project/EditProject.vue");
};
//OrganizationProject
var OrganizationProjectDetail = function () {
    return import("@/presentation/views/organization/project-detail/ProjectDetail.vue");
};
var OrganizationProjectDetailInfo = function () {
    return import("@/presentation/views/organization/project-detail/information/ProjectDetailInformation.vue");
};
var OrganizationProjectDetailQA = function () {
    return import("@/presentation/views/organization/project-detail/question-answer/QuestionAnswer.vue");
};
var OrganizationProjectDetailManagement = function () {
    return import("@/presentation/views/organization/project-detail/management/ProjectDetailManagement.vue");
};
var OrganizationProjectDetailMatches = function () {
    return import("@/presentation/views/organization/project-detail/matches/ProjectDetailMatches.vue");
};
var OrganizationProjectDetailContractorManagement = function () {
    return import("@/presentation/views/organization/project-detail/contractor/ProjectContractorManagement.vue");
};
// project search
var ProjectSearch = function () {
    return import("@/presentation/views/project/search/Search.vue");
};
// project apply
var ProjectApply = function () {
    return import("@/presentation/views/user/project-apply/ProjectApply.vue");
};
var ProjectFormApply = function () {
    return import("@/presentation/views/user/project-apply/form-apply/FormApply.vue");
};
var ProjectConfirmApply = function () {
    return import("@/presentation/views/user/project-apply/confirm/Confirm.vue");
};
//Other Organization Profile
var OtherProfile = function () {
    return import("@/presentation/views/organization/other-profile/OtherProfile.vue");
};
var OtherOrganizationDetailProfile = function () {
    return import("@/presentation/views/organization/other-profile/info/Info.vue");
};
var OtherOrganizationRecruitting = function () {
    return import("@/presentation/views/organization/other-profile/recruiting/Recruiting.vue");
};
// NGリスト
var OrganizationNgList = function () {
    return import("@/presentation/views/organization/ng-list/NgList.vue");
};
// members
var Members = function () {
    return import("@/presentation/views/members/Members.vue");
};
// team
var Teams = function () {
    return import("@/presentation/views/teams/list/List.vue");
};
var TeamsDetail = function () {
    return import("@/presentation/views/teams/detail/Detail.vue");
};
var CreateTeam = function () {
    return import("@/presentation/views/user/team/create-team/CreateTeam.vue");
};
var ConfirmTeam = function () {
    return import("@/presentation/views/user/team/confirm-team/ConfirmTeam.vue");
};
var TeamInvited = function () {
    return import("@/presentation/views/user/team/team-invited/TeamInvited.vue");
};
var TeamInviteExpired = function () {
    return import("@/presentation/views/user/team/team-invite-expired/TeamInviteExpired.vue");
};
var ChangeLeaderRequested = function () {
    return import("@/presentation/views/user/team/change-leader-requested/ChangeLeaderRequested.vue");
};
// other screen
var Help = function () {
    return import("@/presentation/views/help/Help.vue");
};
var Contact = function () {
    return import("@/presentation/views/contact/Contact.vue");
};
var AIQuest = function () {
    return import("@/presentation/views/ai-quest/AIQuest.vue");
};
var routes = [
    {
        path: "/",
        name: "Top",
        component: LoginPage,
        meta: {
            allowAccess: [NOT_LOGGIN]
        }
    },
    {
        path: "/organization/select",
        name: "SelectOrganization",
        component: SelectOrganization,
        meta: {
            allowAccess: [FOR_ORGANIZATION]
        }
    },
    {
        path: "/organization/login",
        name: "OrganizationLogin",
        component: OrganizationLogin,
        meta: {
            allowAccess: [NOT_LOGGIN]
        }
    },
    {
        path: "/organization/reset_password",
        name: "OrganizationResetPassword",
        component: OrganizationResetPassword,
        beforeEnter: function (to, from, next) {
            next();
        },
        meta: {
            allowAccess: [NOT_LOGGIN]
        }
    },
    {
        path: "/organization/reset_password_success",
        name: "OrganizationResetPasswordSuccess",
        component: OrganizationResetPasswordSuccess,
        meta: {
            allowAccess: [NOT_LOGGIN]
        }
    },
    {
        path: "/organization/reset_password_fail",
        name: "OrganizationResetPasswordExpired",
        component: OrganizationResetPasswordFail,
        meta: {
            allowAccess: [NOT_LOGGIN]
        }
    },
    {
        path: "/user/register",
        name: "UserRegister",
        component: UserRegister,
        redirect: "/user/register/profile",
        children: [
            {
                path: "profile",
                name: "UserRegisterProfile",
                component: UserRegisterProfile,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "confirm",
                name: "UserRegisterConfirm",
                component: UserRegisterConfirm,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "completed",
                name: "UserRegisterCompleted",
                component: UserRegisterCompleted,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            }
        ]
    },
    {
        path: "/organization/dashboard",
        name: "OrganizationDashboard",
        component: OrganizationDashboard,
        redirect: "/organization/dashboard/projects",
        children: [
            {
                path: "/organization/dashboard/projects",
                name: "OrganizationDashboardProjects",
                component: OrganizationDashboardListProjects,
                meta: {
                    allowAccess: [FOR_ORGANIZATION]
                }
            },
            {
                path: "/organization/dashboard/incentives",
                name: "OrganizationDashboardIncentives",
                component: OrganizationDashboardListIncentives,
                meta: {
                    allowAccess: [FOR_ORGANIZATION]
                }
            }
        ]
    },
    {
        path: "/project/create",
        name: "OrganizationDashboardCreateProject",
        component: OrganizationDashboardCreateProject,
        beforeEnter: function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch("checkActiveTeamSelect")];
                    case 1:
                        _a.sent();
                        if (store.state.isDisabled) {
                            next(RoutePath.ORGANIZATION_DASHBOARD);
                        }
                        else {
                            next();
                        }
                        return [2 /*return*/];
                }
            });
        }); }
    },
    {
        path: "/project/confirm",
        name: "OrganizationDashboardConfirmProject",
        component: OrganizationDashboardConfirmProject,
        meta: {
            allowAccess: [FOR_ORGANIZATION]
        }
    },
    {
        path: "/project/:projectId/edit",
        name: "OrganizationProjectEdit",
        component: OrganizationMyProfileEditProject,
        meta: {
            allowAccess: [FOR_ORGANIZATION]
        },
        beforeEnter: function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch("checkActiveTeamSelect")];
                    case 1:
                        _a.sent();
                        if (store.state.isDisabled) {
                            next(RoutePath.ORGANIZATION_DASHBOARD);
                        }
                        else {
                            next();
                        }
                        return [2 /*return*/];
                }
            });
        }); }
    },
    {
        path: "/user/dashboard",
        name: "UserDashboard",
        component: UserDashboard,
        redirect: "/user/dashboard/projects",
        children: [
            {
                path: "/user/dashboard/projects",
                name: "UserDashboardProjects",
                component: UserDashboardProjects,
                meta: {
                    allowAccess: [FOR_USER]
                }
            },
            {
                path: "/user/dashboard/incentives",
                name: "UserDashboardIncentives",
                component: UserDashboardIncentives,
                meta: {
                    allowAccess: [FOR_USER]
                }
            }
        ]
    },
    {
        path: "/organization/register",
        component: OrganizationRegister,
        children: [
            {
                path: "profile",
                name: "OrganizationRegisterProfile",
                component: OrganizationRegisterProfile,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "send_mail",
                name: "OrganizationRegisterSendMail",
                component: OrganizationRegisterSendMail,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "detail_profile",
                name: "OrganizationRegisterDetailProfile",
                component: OrganizationRegisterDetailProfile,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "confirm",
                name: "OrganizationRegisterConfirm",
                component: OrganizationRegisterConfirm,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "completed",
                name: "OrganizationRegisterCompleted",
                component: OrganizationRegisterCompleted,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "expired",
                name: "OrganizationRegisterExpired",
                component: OrganizationRegisterExpired,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "invalid_url",
                name: "OrganizationRegisterInvalidUrl",
                component: OrganizationRegisterInvalidUrl,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            }
        ]
    },
    {
        path: "/organization/register-invited",
        component: OrganizationRegisterInvited,
        children: [
            {
                path: "profile",
                name: "OrganizationRegisterInvitedProfile",
                component: OrganizationRegisterInvitedProfile,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "detail_profile",
                name: "OrganizationRegisterInvitedDetailProfile",
                component: OrganizationRegisterInvitedDetailProfile,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "confirm",
                name: "OrganizationRegisterInvitedConfirm",
                component: OrganizationRegisterInvitedConfirm,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "completed",
                name: "OrganizationRegisterInvitedCompleted",
                component: OrganizationRegisterInvitedCompleted,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            },
            {
                path: "composition-confirm",
                name: "OrganizationRegisterInvitedCompositionConfirm",
                component: OrganizationRegisterInvitedCompositionConfirm,
                meta: {
                    allowAccess: [ALLOW_ALL]
                }
            },
            {
                path: "expired",
                name: "OrganizationRegisterInvitedExpired",
                component: OrganizationRegisterInvitedExpired,
                meta: {
                    allowAccess: [ALLOW_ALL]
                }
            },
            {
                path: "invalid_url",
                name: "OrganizationRegisterInvitedInvalidUrl",
                component: OrganizationRegisterInvitedInvalidUrl,
                meta: {
                    allowAccess: [NOT_LOGGIN]
                }
            }
        ]
    },
    {
        path: "/organization/profile",
        name: "MyProfile",
        component: MyProfile,
        redirect: "/organization/profile/info",
        children: [
            {
                path: "info",
                name: "OrganizationDetailProfile",
                component: OrganizationDetailProfile,
                meta: {
                    allowAccess: [FOR_ORGANIZATION]
                }
            },
            {
                path: "recruiting",
                name: "OrganizationRecruitting",
                component: OrganizationRecruitting,
                meta: {
                    allowAccess: [FOR_ORGANIZATION]
                }
            },
            {
                path: "member",
                name: "OrganizationMember",
                component: OrganizationMember,
                meta: {
                    allowAccess: [FOR_ORGANIZATION]
                }
            }
        ]
    },
    {
        path: "/organization/:organizationId",
        name: "OtherProfile",
        component: OtherProfile,
        redirect: function (to) {
            var hash = to.hash, params = to.params, query = to.query;
            if (params.organizationId) {
                return "/organization/" + params.organizationId + "/info";
            }
            else {
                return '/404';
            }
        },
        children: [
            {
                path: "info",
                name: "OtherOrganizationDetailProfile",
                component: OtherOrganizationDetailProfile,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: "recruiting",
                name: "OtherOrganizationRecruitting",
                component: OtherOrganizationRecruitting,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            }
        ]
    },
    {
        path: "/organization/ng_list",
        name: "OrganizationNgList",
        component: OrganizationNgList,
        meta: {
            allowAccess: [FOR_ORGANIZATION]
        }
    },
    {
        path: "/404",
        name: "404",
        component: PageNotFound,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        redirect: '/404'
    },
    {
        path: "/invalid",
        name: "InvalidAccount",
        component: InvalidAccount,
    },
    {
        path: "/outside_registration",
        name: "OutsideRegistration",
        component: OutsideRegistration,
    },
    {
        path: "/user/profile/:userId",
        name: "UserProfile",
        component: UserProfile,
        redirect: function (to) {
            var hash = to.hash, params = to.params, query = to.query;
            if (params.userId) {
                return "/user/profile/" + params.userId + "/info";
            }
            else {
                return '/404';
            }
        },
        children: [
            {
                path: "info",
                name: "UserProfileInfo",
                component: UserProfileInfo,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: "team",
                name: "UserProfileTeam",
                component: UserProfileTeam,
                beforeEnter: function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, store.dispatch("checkActiveTeamInput")];
                            case 1:
                                _a.sent();
                                next();
                                return [2 /*return*/];
                        }
                    });
                }); },
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: "skill",
                name: "UserProfileSkill",
                component: UserProfileSkill,
                beforeEnter: function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, store.dispatch("checkActiveTeamInput")];
                            case 1:
                                _a.sent();
                                next();
                                return [2 /*return*/];
                        }
                    });
                }); },
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: "activity",
                name: "UserProfileActivity",
                component: UserProfileActivity,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: "service",
                name: "UserProfileService",
                component: UserProfileService,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            }
        ],
    },
    //projects
    {
        path: "/project",
        name: "OrganizationProjectDetail",
        component: OrganizationProjectDetail,
        redirect: "/project/search",
        children: [
            {
                path: ":id",
                name: "OrganizationProjectDetail",
                component: OrganizationProjectDetailInfo,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: ":id/question_answer",
                name: "OrganizationProjectDetailQA",
                component: OrganizationProjectDetailQA,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: ":id/management/:team_id",
                name: "OrganizationProjectDetailManagement",
                component: OrganizationProjectDetailManagement,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: ":id/matches",
                name: "OrganizationProjectDetailMatches",
                component: OrganizationProjectDetailMatches,
                meta: {
                    allowAccess: [FOR_ORGANIZATION, FOR_USER]
                }
            },
            {
                path: ":id/contractor/:team_id",
                name: "OrganizationProjectDetailContractorManagement",
                component: OrganizationProjectDetailContractorManagement,
                meta: {
                    allowAccess: [FOR_ORGANIZATION]
                }
            }
        ]
    },
    {
        path: "/project/search",
        name: "ProjectSearch",
        component: ProjectSearch,
        meta: {
            allowAccess: [FOR_ORGANIZATION, FOR_USER]
        }
    },
    {
        path: "/project/:projectId/apply",
        name: "ProjectApply",
        component: ProjectApply,
        redirect: function (to) {
            var hash = to.hash, params = to.params, query = to.query;
            if (params.projectId) {
                return '/project/' + params.projectId + '/apply/info';
            }
            else {
                return '/404';
            }
        },
        children: [
            {
                path: "info",
                name: "ProjectFormApply",
                component: ProjectFormApply,
                meta: {
                    allowAccess: [FOR_USER]
                }
            },
            {
                path: "confirm",
                name: "ProjectConfirmApply",
                component: ProjectConfirmApply,
                meta: {
                    allowAccess: [FOR_USER]
                }
            }
        ]
    },
    {
        path: "/members",
        name: "Members",
        component: Members,
        meta: {
            allowAccess: [FOR_ORGANIZATION, FOR_USER]
        }
    },
    {
        path: "/teams",
        name: "Teams",
        component: Teams,
        meta: {
            allowAccess: [FOR_ORGANIZATION, FOR_USER]
        }
    },
    {
        path: "/teams/:id",
        name: "TeamsDetail",
        component: TeamsDetail,
        meta: {
            allowAccess: [FOR_ORGANIZATION]
        }
    },
    {
        path: "/team/create",
        name: "CreateTeam",
        component: CreateTeam,
        beforeEnter: function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch("checkActiveTeamInput")];
                    case 1:
                        _a.sent();
                        if (store.state.isDisabledTeams) {
                            next(RoutePath.USER_DASHBOARD);
                        }
                        else {
                            next();
                        }
                        return [2 /*return*/];
                }
            });
        }); },
        meta: {
            allowAccess: [FOR_USER]
        }
    },
    {
        path: "/team/confirm",
        name: "ConfirmTeam",
        component: ConfirmTeam,
        meta: {
            allowAccess: [FOR_USER]
        }
    },
    {
        path: "/team/invited",
        name: "TeamInvited",
        component: TeamInvited,
        meta: {
            allowAccess: [FOR_USER]
        }
    },
    {
        path: "/team/change_leader_requested",
        name: "ChangeLeaderRequested",
        component: ChangeLeaderRequested,
        meta: {
            allowAccess: [FOR_USER]
        }
    },
    {
        path: "/team/expired",
        name: "TeamInvitedExpired",
        component: TeamInviteExpired,
        meta: {
            allowAccess: [FOR_USER]
        }
    },
    {
        path: "/help",
        name: "Help",
        component: Help,
        meta: {
            allowAccess: [ALLOW_ALL]
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            allowAccess: [ALLOW_ALL]
        }
    },
    {
        path: "/ai-quest",
        name: "AIQuest",
        component: AIQuest,
        meta: {
            allowAccess: [ALLOW_ALL]
        }
    }
];
var router = createRouter({
    history: createWebHistory("/"),
    routes: routes,
    scrollBehavior: function (to, from, savedPosition) {
        // always scroll to top
        return { top: 0 };
    },
});
export default router;
router.beforeEach(function (to, from, next) {
    if (from.name != to.name) {
        if (to.path == RoutePath.TOP) {
            if (CommonService.isUser()) {
                next(RoutePath.USER_DASHBOARD);
            }
            if (CommonService.isOrganization()) {
                next(RoutePath.ORGANIZATION_DASHBOARD);
            }
        }
        if (to.path == RoutePath.ORGANIZATION_RESET_PASSWORD) {
            var token = to.query.forgot_token;
            return authInteractor
                .organizationValidateResetToken(token)
                .then(function (result) {
                next();
            }).catch(function (error) {
                if (error.code != 403) {
                    next(RoutePath.ORGANIZATION_RESET_PASSWORD_FAIL);
                }
                else {
                    next(RoutePath.PAGE_404);
                }
            });
        }
        if (!to.meta) {
            next();
        }
        if (!to.meta.allowAccess) {
            next();
        }
        if (to.meta.allowAccess.includes(ALLOW_ALL)) {
            next();
        }
        if (to.meta.allowAccess.includes(NOT_LOGGIN)) {
            if (CommonService.isUser() || CommonService.isOrganization()) {
                if (to.name != "OrganizationResetPasswordExpired") {
                    next("/");
                }
            }
            next();
        }
        if (to.meta.allowAccess.includes(FOR_USER) && to.meta.allowAccess.includes(FOR_ORGANIZATION)) {
            if (CommonService.isUser() || CommonService.isOrganization()) {
                next();
            }
        }
        if (to.meta.allowAccess.includes(FOR_USER)) {
            if (CommonService.isUser()) {
                next();
            }
            else {
                if (CommonService.isOrganization()) {
                    next(RoutePath.PAGE_404);
                }
                else {
                    if (from.redirectedFrom) {
                        store.commit("setCallBackUrl", to.fullPath);
                        next(RoutePath.LOGIN);
                    }
                    else {
                        store.commit("setWaitAuthCheck", true);
                        store.commit("setCallBackUrl", to.fullPath);
                        return;
                    }
                }
            }
        }
        if (to.meta.allowAccess.includes(FOR_ORGANIZATION)) {
            if (CommonService.isOrganization()) {
                next();
            }
            else {
                if (CommonService.isUser()) {
                    next(RoutePath.PAGE_404);
                }
                else {
                    store.commit("setCallBackUrl", to.fullPath);
                    next(RoutePath.LOGIN);
                }
            }
        }
        next();
    }
    else {
        if (from.params.team_id !== to.params.team_id) {
            next();
        }
    }
});
