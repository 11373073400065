import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var DeliverableApi = /** @class */ (function () {
    function DeliverableApi() {
    }
    DeliverableApi.prototype.getDeliverable = function (project_id, team_id) {
        return http.get("/deliverable", { project_id: project_id, team_id: team_id });
    };
    DeliverableApi.prototype.createDeliverable = function (deliverable) {
        return http.post("/deliverable", deliverable);
    };
    DeliverableApi.prototype.updateDeliverable = function (deliverable) {
        return http.put("/deliverable", deliverable);
    };
    DeliverableApi.prototype.browseByOrganization = function (id, publish, publish_to_badge, project_name_after_change) {
        return http.put("/deliverable/" + id + "/browsed", { publish: publish, publish_to_badge: publish_to_badge, project_name_after_change: project_name_after_change });
    };
    DeliverableApi.prototype.deleteDeliverableFile = function (fileId) {
        return http.delete("/deliverable/file/" + fileId, {});
    };
    DeliverableApi = __decorate([
        injectable()
    ], DeliverableApi);
    return DeliverableApi;
}());
export default DeliverableApi;
