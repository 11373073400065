import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var AuthApi = /** @class */ (function () {
    function AuthApi() {
    }
    AuthApi.prototype.registerValidate = function (user) {
        return http.post("/users/register/validate_account", user);
    };
    AuthApi.prototype.updateInforUser = function (user) {
        return http.put("/users/update", user);
    };
    AuthApi.prototype.getUserAccountInfo = function () {
        return http.get("/users/me");
    };
    AuthApi.prototype.logout = function () {
        return http.post("/users/logout", {});
    };
    AuthApi.prototype.getUserProfile = function (account_id) {
        return http.get("/users/profile", { account_id: account_id });
    };
    AuthApi.prototype.updateUserProfile = function (user) {
        return http.put("/users/profile/update", user);
    };
    AuthApi.prototype.getPreSignedUrlPr = function () {
        return http.get("/users/pre-signed-url/pr");
    };
    AuthApi.prototype.deleteFilesUserPr = function () {
        return http.delete("/files/user-pr", {});
    };
    AuthApi.prototype.getUserProfileTeam = function (account_id) {
        return http.get("/users/profile/team", { account_id: account_id });
    };
    AuthApi.prototype.getUserProfileSkills = function (account_id) {
        return http.get("/users/profile/skills", { account_id: account_id });
    };
    AuthApi.prototype.updateUserProfileSkills = function (skills) {
        return http.put("/users/profile/skills/update", skills);
    };
    AuthApi.prototype.getUserProfileActivities = function (account_id) {
        return http.get("/users/profile/activities", { account_id: account_id });
    };
    AuthApi.prototype.updateUserProfileActivities = function (activities) {
        return http.put("/users/profile/activities/update", activities);
    };
    AuthApi.prototype.getMyRating = function (account_id, page, limit) {
        return http.get("/users/profile/rating", { account_id: account_id, page: page, limit: limit });
    };
    AuthApi.prototype.getListUserProjects = function (param) {
        return http.get("/users/dashboard/projects", param);
    };
    AuthApi.prototype.getUserProfileMenu = function (account_id) {
        return http.get("/users/profile/menu", { account_id: account_id });
    };
    AuthApi.prototype.checkTutorial = function () {
        return http.get("/users/tutorial/check");
    };
    AuthApi.prototype.readTutorial = function () {
        return http.post("/users/tutorial/read", {});
    };
    AuthApi.prototype.userInitCheck = function () {
        return http.get("/users/init/check");
    };
    AuthApi.prototype.userSmsRead = function () {
        return http.post("/users/sms/read", {});
    };
    AuthApi.prototype.userTeamInvitation = function () {
        return http.get("/users/news/team_invitation", {});
    };
    AuthApi = __decorate([
        injectable()
    ], AuthApi);
    return AuthApi;
}());
export default AuthApi;
