import { defineComponent, onMounted, onUnmounted, reactive } from "vue";
export default defineComponent({
    name: "AlertPopup",
    props: [
        "title",
        "content"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            title: "",
            content: ""
        });
        var onEscapeClose = function (e) {
            if (e.keyCode === 27) {
                emit("close");
            }
        };
        onMounted(function () {
            state.title = props.title ? props.title : "";
            state.content = props.content ? props.content : "";
            document.addEventListener('keydown', onEscapeClose);
        });
        onUnmounted(function () {
            document.removeEventListener('keydown', onEscapeClose);
        });
        return {
            state: state
        };
    }
});
