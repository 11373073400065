export default {
    TOP: "/",
    LOGIN: "/",
    ORGANIZATION_LOGIN: "/organization/login",
    USER_REGISTER: "/user/register",
    USER_REGISTER_PROFILE: "/user/register/profile",
    USER_REGISTER_CONFIRM: "/user/register/confirm",
    USER_REGISTER_COMPLETED: "/user/register/completed",
    USER_DASHBOARD: "/user/dashboard",
    USER_DASHBOARD_PROJECTS: "/user/dashboard/projects",
    USER_DASHBOARD_INCENTIVES: "/user/dashboard/incentives",
    DASHBOARD: "/dashboard",
    ORGANIZATION_REGISTER: "/organization/register/profile",
    ORGANIZATION_REGISTER_DETAIL: "/organization/register/detail_profile",
    ORGANIZATION_RESET_PASSWORD: "/organization/reset_password",
    ORGANIZATION_RESET_PASSWORD_SUCCESS: "/organization/reset_password_success",
    ORGANIZATION_RESET_PASSWORD_FAIL: "/organization/reset_password_fail",
    ORGANIZATION_DASHBOARD: "/organization/dashboard",
    SELECT_ORGANIZATION: "/organization/select",
    ORGANIZATION_REGISTER_EXPIRED: "/organization/register/expired",
    ORGANIZATION_REGISTER_INVITED: "/organization/register-invited/profile",
    ORGANIZATION_REGISTER_INVITED_DETAIL: "/organization/register-invited/detail_profile",
    ORGANIZATION_REGISTER_INVITED_CONFIRM: "/organization/register-invited/confirm",
    ORGANIZATION_REGISTER_INVITED_COMPLETED: "/organization/register-invited/completed",
    ORGANIZATION_REGISTER_INVITED_EXPIRED: "/organization/register-invited/expired",
    ORGANIZATION_REGISTER_INVALID_URL: "/organization/register/invalid_url",
    ORGANIZATION_REGISTER_INVITED_INVALID_URL: "/organization/register-invited/invalid_url",
    ENGINEER_REGISTER: "/engineer/register",
    ORGANIZATION_DASHBOARD_PROJECTS: "/organization/dashboard/projects",
    ORGANIZATION_DASHBOARD_INCENTIVES: "/organization/dashboard/incentives",
    ORGANIZATION_DASHBOARD_CREATE_PROJECT: "/project/create",
    ORGANIZATION_PROFILE: "/organization/profile",
    ORGANIZATION_NG_LIST: "/organization/ng_list",
    PROJECT_DETAIL: "/project/",
    USER_PROFILE: "/user/profile",
    USER_PROFILE_INFO: "/user/profile/info",
    USER_PROFILE_TEAM: "/user/profile/team",
    USER_PROFILE_SKILL: "/user/profile/skill",
    USER_PROFILE_ACTIVITY: "/user/profile/activity",
    USER_PROFILE_SERVICE: "/user/profile/service",
    ORDER: "/order",
    PROJECT: "/project",
    MEMBERS: "/members",
    PAGE_404: "/404",
    ORGANIZATION_CONFIRM_PROJECT: "/project/confirm",
    ORGANIZATION_EDIT_PROJECT: "/project/id/edit",
    ORGANIZATION_MESSAGES: "",
    PROJECT_SEARCH: "/project/search",
    TEAMS: "/teams",
    CREATE_TEAM: "/team/create",
    CONFIRM_TEAM: "/team/confirm",
    INVITED_TEAM: "/team/invited",
    TEAM: "/team/",
    CONTACT: "/contact",
    HELP: "/help",
    AIQUEST: "/ai-quest",
    TEAM_INVITE_EXPIRED: "/team/expired",
    PLEDGE: "/pledge"
};
