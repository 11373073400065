import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var AuthApi = /** @class */ (function () {
    function AuthApi() {
    }
    AuthApi.prototype.organizationLogin = function (email, password, remember_me) {
        if (remember_me === void 0) { remember_me = false; }
        return http.post("/organizations/members/login", {
            email: email,
            password: password,
            remember_me: remember_me
        });
    };
    AuthApi.prototype.organizationSendMailResetPassword = function (email) {
        return http.post("/organizations/members/password/reset/send_mail", {
            email: email
        });
    };
    AuthApi.prototype.organizationValidateResetToken = function (reset_token) {
        return http.post("/organizations/members/password/reset/validate_token", {
            reset_token: reset_token
        });
    };
    AuthApi.prototype.organizationResetPassword = function (reset_token, password, password_confirmation) {
        return http.post("/organizations/members/password/reset", {
            reset_token: reset_token,
            password: password,
            password_confirmation: password_confirmation
        });
    };
    AuthApi.prototype.authCheck = function () {
        return http.post("/auth/check", {});
    };
    AuthApi.prototype.organizationLogout = function () {
        return http.post("/organizations/members/logout", {});
    };
    AuthApi.prototype.getAccountInfo = function () {
        return http.get("/user/auth/me");
    };
    AuthApi.prototype.smsCheck = function () {
        return http.post("/users/auth/sms/check", {});
    };
    AuthApi = __decorate([
        injectable()
    ], AuthApi);
    return AuthApi;
}());
export default AuthApi;
