import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import QuestionnaireReps from "@/domain/repositories/QuestionnaireReps";
var QuestionnaireInteractor = /** @class */ (function () {
    function QuestionnaireInteractor() {
        this.repos = DIContainer.instance.get(QuestionnaireReps);
    }
    QuestionnaireInteractor.prototype.isQuestionnaireAnswered = function (team_id) {
        return this.repos.isQuestionnaireAnswered(team_id);
    };
    QuestionnaireInteractor = __decorate([
        injectable()
    ], QuestionnaireInteractor);
    return QuestionnaireInteractor;
}());
export default QuestionnaireInteractor;
