import { __decorate } from "tslib";
import http from "@/data/datasource/api/BaseHttp";
import { injectable } from "inversify";
var OrganizationProjectApi = /** @class */ (function () {
    function OrganizationProjectApi() {
    }
    /* eslint-disable @typescript-eslint/camelcase */
    OrganizationProjectApi.prototype.getListOrganizationProjects = function (param) {
        return http.get("/organizations/projects", param);
    };
    OrganizationProjectApi.prototype.getOrganizationProjectById = function (projectId) {
        return http.get('/organizations/projects' + "/" + projectId);
    };
    OrganizationProjectApi.prototype.getOrganizationProjectApplicants = function (project_id, keyword, orderColumn, orderType, limit, page, filterStatus, filterHidden) {
        if (keyword === void 0) { keyword = null; }
        return http.get('/organizations/projects/applicants', {
            project_id: project_id,
            "order[column]": orderColumn,
            "order[type]": orderType,
            keyword: keyword,
            limit: limit,
            page: page,
            "filter[status]": filterStatus,
            "filter[hidden]": filterHidden
        });
    };
    OrganizationProjectApi.prototype.getOrganizationProjectAssignable = function (project_id, data) {
        return http.get("/organizations/projects/" + project_id + "/assignable", data);
    };
    OrganizationProjectApi.prototype.updateOrganizationProjectAssign = function (project_id, data) {
        return http.put("/organizations/projects/" + project_id + "/assign", data);
    };
    OrganizationProjectApi.prototype.updateOrganizationProjectApplicant = function (applicant_id, data) {
        return http.put("/organizations/projects/applicants/" + applicant_id + "/update", data);
    };
    OrganizationProjectApi.prototype.getOrganizationProjectQuestion = function (params) {
        return http.get('/organizations/projects/questions', params);
    };
    OrganizationProjectApi.prototype.postOrganizationProjectQuestion = function (data) {
        return http.post('/organizations/projects/questions/create', data);
    };
    OrganizationProjectApi.prototype.postOrganizationProjectQuestionResponse = function (data) {
        return http.post('/organizations/projects/questions/responses/create', data);
    };
    OrganizationProjectApi.prototype.getDetailApplicant = function (applicant_id) {
        return http.get('/organizations/projects/applicants/' + applicant_id);
    };
    OrganizationProjectApi.prototype.updateOrganizationProject = function (projectId, data) {
        return http.put("/organizations/projects/" + projectId + "/update", data);
    };
    OrganizationProjectApi.prototype.getContractor = function (project_id) {
        return http.get("/organizations/projects/contractor", { project_id: project_id });
    };
    OrganizationProjectApi.prototype.getContractors = function (project_id) {
        return http.get("/organizations/projects/contractors", { project_id: project_id });
    };
    OrganizationProjectApi.prototype.rating = function (applicant_id, rating_point, rating_comment) {
        return http.post("/rating", { applicant_id: applicant_id, rating: rating_point, rating_comment: rating_comment });
    };
    OrganizationProjectApi.prototype.ratingOrganize = function (project_id, rating_point, rating_comment) {
        return http.post("/rating", { project_id: project_id, rating: rating_point, rating_comment: rating_comment });
    };
    OrganizationProjectApi.prototype.checkOwner = function (organization_id) {
        return http.get("/organizations/check_is_owner", { organization_id: organization_id });
    };
    OrganizationProjectApi.prototype.creatProject = function (project) {
        return http.post("/organizations/projects/create", project);
    };
    OrganizationProjectApi.prototype.requestSMS = function (data) {
        return http.post("/users/auth/sms/request", data);
    };
    OrganizationProjectApi.prototype.authorizeSMS = function (data) {
        return http.post("/users/auth/sms/authorize", data);
    };
    OrganizationProjectApi.prototype.getApplicantsCount = function (project_id) {
        return http.get("/organizations/projects/" + project_id + "/count");
    };
    OrganizationProjectApi = __decorate([
        injectable()
    ], OrganizationProjectApi);
    return OrganizationProjectApi;
}());
export default OrganizationProjectApi;
