import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import ProjectReps from "@/domain/repositories/ProjectReps";
var ProjectInteractor = /** @class */ (function () {
    function ProjectInteractor() {
        this.repos = DIContainer.instance.get(ProjectReps);
    }
    ProjectInteractor.prototype.getProjectCategories = function () {
        return this.repos.getProjectCategories();
    };
    ProjectInteractor.prototype.searchProject = function (params) {
        return this.repos.searchProject(params);
    };
    ProjectInteractor.prototype.getCurrentUserProjectApplyInfo = function (project_id) {
        return this.repos.getCurrentUserProjectApplyInfo(project_id);
    };
    ProjectInteractor.prototype.cancelProjectApplyCheck = function (project_id) {
        return this.repos.cancelProjectApplyCheck(project_id);
    };
    ProjectInteractor.prototype.checkProjectApplyRequirement = function () {
        return this.repos.checkProjectApplyRequirement();
    };
    ProjectInteractor.prototype.applyProject = function (projectId, data) {
        return this.repos.applyProject(projectId, data);
    };
    ProjectInteractor = __decorate([
        injectable()
    ], ProjectInteractor);
    return ProjectInteractor;
}());
export default ProjectInteractor;
