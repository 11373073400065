import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import OrganizationRegisterReps from "@/domain/repositories/OrganizationRegisterReps";
var OrganizationRegisterInteractor = /** @class */ (function () {
    function OrganizationRegisterInteractor() {
        this.repos = DIContainer.instance.get(OrganizationRegisterReps);
    }
    /* eslint-disable @typescript-eslint/camelcase */
    OrganizationRegisterInteractor.prototype.validate = function (email, password, password_confirmation, recaptcha_token) {
        return this.repos.validate(email, password, password_confirmation, recaptcha_token);
    };
    OrganizationRegisterInteractor.prototype.register = function (organization) {
        return this.repos.register(organization);
    };
    OrganizationRegisterInteractor.prototype.resendEmail = function (organization_member_id, organization_id) {
        return this.repos.resendEmail(organization_member_id, organization_id);
    };
    OrganizationRegisterInteractor.prototype.validateTokenRegister = function (register_token, organization_member_id, organization_id) {
        return this.repos.validateTokenRegister(register_token, organization_member_id, organization_id);
    };
    OrganizationRegisterInteractor.prototype.validateInvite = function (register_token, organization_member_id, organization_id) {
        return this.repos.validateInvite(register_token, organization_member_id, organization_id);
    };
    OrganizationRegisterInteractor.prototype.validateInviteAccount = function (email, password, password_confirmation, recaptcha_token) {
        return this.repos.validateInviteAccount(email, password, password_confirmation, recaptcha_token);
    };
    OrganizationRegisterInteractor.prototype.registerInvited = function (organization_member) {
        return this.repos.registerInvited(organization_member);
    };
    OrganizationRegisterInteractor.prototype.validateInviteComposition = function (register_token, organization_member_id, organization_id) {
        return this.repos.validateInviteComposition(register_token, organization_member_id, organization_id);
    };
    OrganizationRegisterInteractor.prototype.acceptInviteComposition = function (organization_member_id, organization_id) {
        return this.repos.acceptInviteComposition(organization_member_id, organization_id);
    };
    OrganizationRegisterInteractor.prototype.rejectInviteComposition = function (organization_member_id, organization_id) {
        return this.repos.rejectInviteComposition(organization_member_id, organization_id);
    };
    OrganizationRegisterInteractor = __decorate([
        injectable()
    ], OrganizationRegisterInteractor);
    return OrganizationRegisterInteractor;
}());
export default OrganizationRegisterInteractor;
