import { __decorate } from "tslib";
import DIContainer from "@/core/DIContainer";
import { injectable } from "inversify";
import MilestoneReps from "@/domain/repositories/MilestoneReps";
var MilestoneInteractor = /** @class */ (function () {
    function MilestoneInteractor() {
        this.repos = DIContainer.instance.get(MilestoneReps);
    }
    MilestoneInteractor.prototype.getMilestones = function (team_id) {
        return this.repos.getMilestones(team_id);
    };
    MilestoneInteractor.prototype.createMilestone = function (milestone) {
        return this.repos.createMilestone(milestone);
    };
    MilestoneInteractor.prototype.updateMilestone = function (id, milestone) {
        return this.repos.updateMilestone(id, milestone);
    };
    MilestoneInteractor.prototype.updateMilestoneAndCreateQuestionnaire = function (id, milestone) {
        return this.repos.updateMilestoneAndCreateQuestionnaire(id, milestone);
    };
    MilestoneInteractor.prototype.updateMilestoneAndUpdateQuestionnaire = function (id, milestone) {
        return this.repos.updateMilestoneAndUpdateQuestionnaire(id, milestone);
    };
    MilestoneInteractor.prototype.createMilestoneAndCreateQuestionnaire = function (milestone) {
        return this.repos.createMilestoneAndCreateQuestionnaire(milestone);
    };
    MilestoneInteractor.prototype.deleteMilestoneFile = function (milestone_id, file_id) {
        return this.repos.deleteMilestoneFile(milestone_id, file_id);
    };
    MilestoneInteractor = __decorate([
        injectable()
    ], MilestoneInteractor);
    return MilestoneInteractor;
}());
export default MilestoneInteractor;
