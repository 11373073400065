var Project = /** @class */ (function () {
    function Project() {
        this.project_id = null;
        this.title = "";
        this.description = "";
        this.working_period_start_at = "";
        this.working_period_end_at = "";
        this.category_id = null;
        this.file_base64 = "";
        this.progress_status = "";
        this.data_types = [];
        this.available_datetimes = [];
        this.qa = "";
        this.note = "";
    }
    return Project;
}());
export default Project;
